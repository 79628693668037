import * as React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import HomePage from '../home/HomePage';
import SearchPage from '../search/SearchPage';
import PartitionPage from '../partition/PartitionPage';
import SettingsPage from '../settings/SettingsPage';
import CheckoutPage from '../checkout/CheckoutPage';

interface Props {}

const AppRouter = (props: Props) => {
  return (
    <BrowserRouter>
      <Route exact={true} path="/" component={HomePage} />
      <Route path="/search/:barcode" component={SearchPage} />
      <Route path="/partition/:id?" component={PartitionPage} />
      <Route path="/settings" component={SettingsPage} />
      <Route path="/checkout/:vendor" component={CheckoutPage} />
    </BrowserRouter>
  );
};

export default AppRouter;
