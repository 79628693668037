import { RootState } from '../../createStore';

const selectIsLoading = (state: RootState) => state.feedback.isLoading;
const selectError = (state: RootState) => state.feedback.error;
const selectRating = (state: RootState) => state.feedback.rating;
const selectEmail = (state: RootState) => state.feedback.email;
const selectFeedback = (state: RootState) => state.feedback.feedback;
const selectIsSent = (state: RootState) => state.feedback.isSent;

export default {
  selectIsLoading,
  selectError,
  selectRating,
  selectEmail,
  selectFeedback,
  selectIsSent,
};
