import { put, select, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { ApiError } from '../../services/api/apiError';
import actions from './actions';
import { getService } from '../../services';
import selectors from './selectors';
import { Api } from '../../services/api/api';
import { RootState } from '../../createStore';

const sendFeedbackSaga = takeLatest(getType(actions.sendFeedback.request), function* () {
  const api: Api = yield getService('api');
  const platform: string = yield getService('platform');
  const state: RootState = yield select();
  const feedback = {
    numberOfStars: selectors.selectRating(state),
    message: selectors.selectFeedback(state),
    email: selectors.selectEmail(state),
    platform,
  };

  try {
    if (feedback.numberOfStars === undefined) {
      throw new ApiError('Validation', 'Bitte gib eine Sternebewertung ab.');
    } else {
      // @ts-ignore
      yield api.sendFeedback(feedback);
      yield put(actions.sendFeedback.success());
    }
  } catch (e) {
    yield put(actions.sendFeedback.failure(e as ApiError));
  }
});

export default [sendFeedbackSaga];
