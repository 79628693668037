import * as React from 'react';
import { createRef } from 'react';
import { Button, createStyles, makeStyles, Snackbar, Theme, Typography } from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { openUrl } from '../common/utils/window';

interface IProps {
  vendorDisplayName: string;
  eans: string;
  checkoutUrl: string;
}

const BulkCheckoutWizard = (props: IProps & RouteComponentProps) => {
  const classes = useStyles();

  const textarea = createRef<HTMLTextAreaElement>();

  const [snackbarContent, setSnackbarContent] = React.useState<string | undefined>(undefined);

  const onPress = () => {
    if (textarea.current) {
      textarea.current.select();
      document.execCommand('copy');
      setSnackbarContent('EANs wurden in die Zwischenablage kopiert.');
    }
  };

  const onCloseSnackbar = () => {
    setSnackbarContent(undefined);
    openUrl(props.checkoutUrl);
  };

  return (
    <React.Fragment>
      <Typography variant={'h6'}>Sammelübertragung</Typography>
      <Typography>
        Der Ankäufer {props.vendorDisplayName} ermöglicht das Übertragen mehrerer Produkte in einem Schritt. Drücke den
        Button um alle Produktnummern zu kopieren. Du wirst anschließend zu {props.vendorDisplayName} weitergeleitet.
        Füge dort die EANs aus der Zwischenablage ein.
      </Typography>
      <Button className={classes.button} variant={'contained'} color={'secondary'} onClick={onPress}>
        Alle Produkte kopieren und weiterleiten
      </Button>
      <textarea className={classes.textarea} ref={textarea} value={props.eans} readOnly={true} />
      <Snackbar open={!!snackbarContent} autoHideDuration={1000} message={snackbarContent} onClose={onCloseSnackbar} />
    </React.Fragment>
  );

  /*

     */
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginTop: theme.spacing(2),
    },
    textarea: {
      position: 'absolute',
      top: -9999,
    },
  })
);

export default withRouter(BulkCheckoutWizard);
