import { useDispatch, useSelector } from 'react-redux';
import actions from './actions';
import { ProductCondition } from './models';
import selectors from './selectors';
import { commonSelectors } from './index';

export function useDisableFirstLaunch() {
  const dispatch = useDispatch();
  return () => {
    dispatch(actions.disableFirstLaunch());
  };
}

export function useAddToShoppingCart() {
  const dispatch = useDispatch();
  return (ean: string, condition: ProductCondition) => dispatch(actions.addShoppingCartEntry({ ean, condition }));
}

export function useShowSnackbar() {
  const dispatch = useDispatch();
  return (message: string) => dispatch(actions.snackbar.show(message));
}

export function useDismissSnackbar() {
  const dispatch = useDispatch();
  return () => dispatch(actions.snackbar.dismiss());
}

export const useVendors = () => useSelector(selectors.selectVendors);
export const useNumberOfVendors = () => useSelector(selectors.selectNumberOfVendors);
export const useNumberOfShoppingCartEntries = () => useSelector(selectors.selectNumberOfShoppingCartEntries);
export const useIsFirstLaunch = (): boolean => useSelector(selectors.selectIsFirstLaunch);
export const useSnackbarContent = () => useSelector(selectors.selectSnackbarContent);
export const useHasProductsInCart = () => useSelector(commonSelectors.selectHasProductsInCart);
