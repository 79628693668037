import { ProductCondition, productConditionToStringMap } from '../common/models';

export function formatMoney(priceInCent: number): string {
  return (
    Number(priceInCent / 100)
      .toFixed(2)
      .replace('.', ',') + ' €'
  );
}

export function getProductConditionDisplayString(condition: ProductCondition): string {
  return productConditionToStringMap.get(condition)!!;
}

export function getProductConditionCode(condition: ProductCondition): number {
  switch (condition) {
    case ProductCondition.LikeNew:
      return 1;
    case ProductCondition.VeryGood:
      return 2;
    case ProductCondition.Good:
      return 3;
    case ProductCondition.Acceptable:
      return 4;
  }
  return 0;
}

export function relativeDate(date: Date): string {
  const delta = Math.round((new Date().getTime() - date.getTime()) / 1000);

  const minute = 60,
    hour = minute * 60,
    day = hour * 24;

  let fuzzy;

  if (delta < 30) {
    fuzzy = 'gerade eben';
  } else if (delta < minute) {
    fuzzy = 'vor ' + delta + ' Sek.';
  } else if (delta < 2 * minute) {
    fuzzy = 'vor einer Min.';
  } else if (delta < hour) {
    fuzzy = 'vor ' + Math.floor(delta / minute) + ' Min.';
  } else if (Math.floor(delta / hour) == 1) {
    fuzzy = 'vor einer Std.';
  } else if (delta < day) {
    fuzzy = 'vor ' + Math.floor(delta / hour) + ' Std.';
  } else if (delta < day * 2) {
    fuzzy = 'gestern, um ' + date.toLocaleTimeString();
  } else {
    fuzzy = date.toLocaleDateString(undefined, {
      day: '2-digit',
      month: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    });
  }
  return fuzzy;
}
