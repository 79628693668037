import { createAction, createAsyncAction } from 'typesafe-actions';
import { FeedbackState } from './reducer';
import { ApiError } from '../../services/api/apiError';

export type FieldName = 'feedback' | 'email' | 'rating';

const setField = createAction('@feedback/SET_FIELD')<FeedbackState[FieldName], FieldName>();
const resetError = createAction('@feedback/RESET_ERROR')();

const sendFeedback = createAsyncAction(
  '@feedback/SEND_FEEDBACK_REQUEST',
  '@feedback/SEND_FEEDBACK_SUCCESS',
  '@feedback/SEND_FEEDBACK_FAILURE'
)<void, void, ApiError>();

export default {
  setField,
  resetError,
  sendFeedback,
};
