import { Button, createStyles, makeStyles, TextField, Theme, Typography } from '@material-ui/core';
import * as React from 'react';
import { ChangeEvent } from 'react';
import { removeNonAlphanumericChars } from '@luso/common/src/utils/helper';

interface Props {
  onBarcode: (barcode: string) => void;
}

const ManualInputTab = (props: Props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState('');

  const onTextChanged = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    setValue(removeNonAlphanumericChars(e.target.value));
  };

  return (
    <div className={classes.container}>
      <form noValidate={false} autoComplete="off" className={classes.form}>
        <TextField
          id="outlined-name"
          label="Barcode"
          className={classes.textField}
          onChange={onTextChanged}
          value={value}
          margin="normal"
          variant="outlined"
        />
        <Button
          variant="contained"
          color="secondary"
          disabled={value.length <= 6}
          className={classes.button}
          onClick={() => props.onBarcode(value)}
        >
          Artikel suchen
        </Button>
      </form>
      <Typography variant="caption" className={classes.caption}>
        Die Barcode-Nummer befindet sich meist auf der Artikel&shy;rückseite oder der Verpackung ober- oder unterhalb
        des Strich&shy;codes. Viele Barcodes sind 13-stellig (EAN13) und bestehen größten&shy;teils aus Ziffern.
        Produkt&shy;bezeichnungen (z.B. der Buch&shy;titel) werden leider nicht unter&shy;stützt.
      </Typography>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      margin: '0 auto',
      padding: theme.spacing(2),
      maxWidth: 500,
    },
    form: {},
    textField: {
      width: '100%',
    },
    button: {
      width: '100%',
      marginTop: theme.spacing(1),
    },
    caption: {
      display: 'block',
      marginTop: theme.spacing(2),
      color: theme.palette.grey[500],
    },
  })
);

export default ManualInputTab;
