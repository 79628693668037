import * as React from 'react';
import { Dialog, IconButton } from '@material-ui/core';
import { DialogContent, DialogTitle } from './InfoDialog';

interface Props {
  icon: React.ReactNode;
  dialogTitle: string;
  dialogContent: React.ReactNode;
}

const DialogButton = (props: Props) => {
  const [isDialogOpen, setDialogOpen] = React.useState(false);

  return (
    <React.Fragment>
      <IconButton onClick={() => setDialogOpen(true)}>{props.icon}</IconButton>
      <Dialog open={isDialogOpen}>
        <DialogTitle onClose={() => setDialogOpen(false)}>{props.dialogTitle}</DialogTitle>
        <DialogContent>{props.dialogContent}</DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default DialogButton;
