import * as React from 'react';
import { AppBar, createStyles, LinearProgress, makeStyles, Theme, Toolbar, Typography } from '@material-ui/core';
import { Page } from '../common/components/Page';
import AddToCartButton from './Components/AddToCartButton';
import BackButton from '../common/components/BackButton';
import { Header } from './Header/Header';
import { RouteComponentProps } from 'react-router-dom';
import PriceCard from './PriceRangeCard/PriceCard';
import ConditionCard from './ConditionCard/ConditionCard';
import { backOrReplace } from '../common/utils/routerUtil';
import ErrorCard from '../common/components/ErrorCard';
import Banner from '../common/components/Banner';
import { Timeline } from '@material-ui/icons';
import { searchHooks } from '@luso/common/src/pages/search';
import { commonHooks } from '@luso/common/src/common';

type IRouteProps = RouteComponentProps<{ barcode: string }>;

type IAllProps = IRouteProps;

const SearchPage = (props: IAllProps) => {
  const classes = useStyles();
  const isLoading = searchHooks.useIsLoading();
  const title = searchHooks.useTitle();
  const ean = searchHooks.useEan();
  const condition = searchHooks.useCondition();
  const imageUrl = searchHooks.useImageUrl();
  const showPriceInfoBanner = searchHooks.useShowPriceInfoBanner();
  const errorMessage = searchHooks.useErrorMessage();

  const addToShoppingCart = commonHooks.useAddToShoppingCart();
  const confirmPriceInfoBanner = searchHooks.useConfirmPriceInfoBanner();

  searchHooks.useFetchProducts(props.match.params.barcode);

  const addToCart = () => {
    if (ean) {
      addToShoppingCart(ean, condition);
    }
    backOrReplace(props);
  };

  return (
    <Page>
      <AppBar position="relative" className={classes.appBar}>
        <Toolbar>
          <BackButton handleBackClicked={() => backOrReplace(props)} />
          <Typography variant="h6" color="inherit">
            Ankaufangebote
          </Typography>
        </Toolbar>
        <Header imageUrl={imageUrl ?? null} title={title} ean={ean} />
        <AddToCartButton isLoading={isLoading} handleAddToCartClicked={addToCart} isFabSubmittable={!!imageUrl} />
      </AppBar>
      {isLoading ? <LinearProgress color="secondary" /> : null}
      <Banner
        open={showPriceInfoBanner}
        label={'Die angezeigten Preise können sich zwischenzeitlich geringfügig geändert haben.'}
        buttonLabel="Verstanden"
        buttonOnClick={confirmPriceInfoBanner}
        showDismissButton={false}
        icon={<Timeline />}
      />

      {!!errorMessage && <ErrorCard errorMessage={errorMessage} />}

      {!!title && (
        <React.Fragment>
          <PriceCard />
          <ConditionCard cardClass={classes.conditionCard} />
        </React.Fragment>
      )}
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      paddingBottom: theme.spacing(3),
    },
    conditionCard: {
      marginTop: 0,
    },
  })
);

export default SearchPage;
