import {
  AppBar,
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  makeStyles,
  Theme,
  Toolbar,
} from '@material-ui/core';
import * as React from 'react';
import EmptyCheckboxIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckboxIcon from '@material-ui/icons/CheckBoxOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import { partitionHooks } from '@luso/common/src/pages/partition';

const BottomAppBar = () => {
  const classes = useStyles();

  const [showDialog, setShowDialog] = React.useState(false);

  const numberOfSelections = partitionHooks.useNumberOfSelections();
  const deleteSelectedProducts = partitionHooks.useDeleteSelectedProducts();

  const areAllSelected = partitionHooks.useAreAllSelected();
  const toggleAll = partitionHooks.useToggleAll();

  const openDialog = () => setShowDialog(true);
  const closeDialog = () => setShowDialog(false);
  const deleteSelection = () => {
    setShowDialog(false);
    deleteSelectedProducts();
  };

  const ConfirmationDialog = () => (
    <Dialog
      open={showDialog}
      onClose={closeDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{'Ausgewählte Artikel wirklich löschen?'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Sollen die ausgewählten Artikel wirklich aus der Versandbox gelöscht werden?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="primary">
          Abbrechen
        </Button>
        <Button onClick={deleteSelection} color="primary" autoFocus>
          Löschen
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <React.Fragment>
      <AppBar position="fixed" color="secondary" className={classes.bottomAppBar}>
        <Toolbar className={classes.bottomToolbar}>
          <Button color="inherit" onClick={toggleAll}>
            {areAllSelected ? <CheckboxIcon /> : <EmptyCheckboxIcon />}
            <span className={classes.marginLeft}>{areAllSelected ? 'Alle abwählen' : 'Alle auswählen'}</span>
          </Button>
          <IconButton color={'inherit'} onClick={openDialog} disabled={!numberOfSelections}>
            <DeleteIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <ConfirmationDialog />
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toggleAllButton: {
      marginLeft: theme.spacing(-2),
    },
    bottomAppBar: {
      bottom: 0,
      top: 'auto',
      position: 'static',
    },
    bottomToolbar: {
      paddingLeft: theme.spacing(0.75),
      justifyContent: 'space-between',
    },
    marginLeft: {
      marginLeft: theme.spacing(1),
    },
  })
);

export default BottomAppBar;
