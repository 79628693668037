export const range = (start: number, end: number): Array<number> => {
  return Array.from('x'.repeat(end - start), (_, i) => start + i);
};

export const dateAddMinutes = (date: Date, minutes: number): Date => {
  return new Date(date.getTime() + minutes * 60000);
};

export const generateGuid = () => {
  const S4 = function () {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  return S4() + S4() + '-' + S4() + '-' + S4() + '-' + S4() + '-' + S4() + S4() + S4();
};

export const removeNonAlphanumericChars = (input: string): string => {
  return input.replace(/[^0-9a-z]/gi, '');
};

type P = {
  valid: boolean;
  test?: string;
};

function test(p: P): P['valid'] extends true ? Required<P> : P {
  return p.valid ? { valid: true, test: '' } : p;
}

function abc() {
  const p: P = {
    valid: true,
  };

  const tested = test(p);
}
