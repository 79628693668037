import { Badge, createStyles, IconButton, makeStyles } from '@material-ui/core';
import * as React from 'react';

interface IProps {
  children: React.ReactNode;
  badgeContent: number;
  onClick: () => void;
  ariaLabel?: string;
}

export function IconButtonWithBadge(props: IProps) {
  const classes = useStyles();

  return (
    <IconButton color="inherit" aria-label={props.ariaLabel} onClick={props.onClick}>
      <Badge classes={{ badge: classes.badge }} badgeContent={props.badgeContent} color="secondary">
        {props.children}
      </Badge>
    </IconButton>
  );
}

const useStyles = makeStyles(() =>
  createStyles({
    badge: {
      animationDuration: '1.5s',
      animationName: '$grow',
    },
    '@keyframes grow': {
      '0%': { transform: 'scale(1.0) translate(50%, -50%)' },
      '50%': { transform: 'scale(1.5) translate(50%, -50%)' },
      '100%': { transform: 'scale(1.0) translate(50%, -50%)' },
    },
  })
);
