import { AppBar, createStyles, makeStyles, Theme, Toolbar, Typography } from '@material-ui/core';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Page } from '../common/components/Page';
import BulkCheckoutWizard from './BulkCheckoutWizard';
import { backOrReplace } from '../common/utils/routerUtil';
import BackButton from '../common/components/BackButton';
import SingleCheckoutWizard from './SingleCheckoutWizard';
import ErrorCard from '../common/components/ErrorCard';
import { checkoutHooks } from '@luso/common/src/pages/checkout';
import { isBulkCheckout } from '@luso/common/src/pages/checkout/models';

type IOwnProps = RouteComponentProps<{ vendor: string }>;

const CheckoutPage = (props: IOwnProps) => {
  const classes = useStyles();
  const checkoutVM = checkoutHooks.useCheckoutVM(props.match.params.vendor);

  const Checkout = () => {
    if (!checkoutVM) {
      return <ErrorCard errorMessage={'Der Checkout ist fehlgeschlagen'} />;
    } else if (isBulkCheckout(checkoutVM)) {
      return <BulkCheckoutWizard {...checkoutVM} />;
    } else {
      return <SingleCheckoutWizard {...checkoutVM} />;
    }
  };

  return (
    <Page>
      <AppBar position="relative">
        <Toolbar>
          <BackButton handleBackClicked={() => backOrReplace(props, '/partition')} />
          <Typography variant="h6" color="inherit">
            Artikel übertragen
          </Typography>
        </Toolbar>
      </AppBar>
      <div className={classes.container}>
        <Checkout />
      </div>
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(2),
    },
  })
);

export default CheckoutPage;
