import * as React from 'react';
import { Link } from '@material-ui/core';
import { Link as DomLink } from 'react-router-dom';
import * as H from 'history';

export const withLink = <P extends object>(Component: React.ComponentType<P>) => (
  props: P & { href: string; target?: string; rel?: string }
) => {
  const { href, target, rel, ...other } = props;

  return <Component {...other} {...({ component: Link, href, target, rel } as any)} />;
};

interface IProps {
  to: H.LocationDescriptor;
}

export const withInternalLink = <P extends object>(Component: React.ComponentType<P>) => (props: P & IProps) => {
  const { to, ...other } = props;
  return <Component {...other} {...({ component: DomLink, to } as any)} />;
};
