import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import * as React from 'react';
import BarcodeScanner from './BarcodeScanner';

interface Props {
  onBarcode?: (barcode: string) => void;
}

const ScanView = (props: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.overlayContainer}>
        <div className={classes.overlay}>
          <Typography variant={'body1'} className={[classes.text, classes.textTop].join(' ')}>
            Jetzt Bücher, DVDs und Spiele scannen und den besten Verkaufspreis finden.
          </Typography>
        </div>
        <div className={`${classes.overlay} ${classes.hole}`} />
        <div className={classes.overlay}>
          <Typography variant={'caption'} className={[classes.text, classes.textBottom].join(' ')}>
            Barcode im Fenster platzieren
          </Typography>
        </div>
      </div>
      <BarcodeScanner onBarcode={props.onBarcode} />
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
    },
    overlayContainer: {
      zIndex: 2,
      position: 'absolute',
      height: '100%',
      width: '100%',
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      height: '35%',
    },
    hole: {
      height: '30%',
      backgroundColor: 'transparent',
      borderLeft: '25px rgba(0, 0, 0, 0.5) solid',
      borderRight: '25px rgba(0, 0, 0, 0.5) solid',
    },
    text: {
      color: 'white',
      textAlign: 'center',
      marginLeft: 10,
      marginRight: 10,
    },
    textBottom: {
      paddingTop: 10,
      display: 'block',
    },
    textTop: {
      paddingTop: 60,
    },
  })
);

export default ScanView;
