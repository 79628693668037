import * as React from 'react';
import {
  createStyles,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ImageWithFallback from '../common/components/ImageWithFallback';
import ChevronIcon from '@material-ui/icons/ChevronRight';
import { openUrl } from '../common/utils/window';
import { ISingleCheckoutProduct } from '@luso/common/src/pages/checkout/models';

interface IProps {
  vendorDisplayName: string;
  products: ISingleCheckoutProduct[];
}

const SingleCheckoutWizard = (props: IProps & RouteComponentProps) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Typography variant={'h6'}>Einzelübertragung</Typography>
      <Typography>
        Zum Ankäufer {props.vendorDisplayName} muss jedes Produkt einzeln übertragen werden. Klicke nacheinander die
        Produkte an und lege sie bei {props.vendorDisplayName} in den Verkaufskorb.
      </Typography>

      <List>
        {props.products.map((p, i) => (
          <ListItem key={`item-${i}`} divider={true} button onClick={() => openUrl(p.checkoutUrl)}>
            <ListItemAvatar>
              <ImageWithFallback className={classes.image} url={p.imageUrl} />
            </ListItemAvatar>
            <ListItemText classes={{ primary: classes.textPrimary }} primary={p.name} secondary={p.ean} />
            <ChevronIcon color={'action'} />
          </ListItem>
        ))}
      </List>
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    image: {
      height: 40,
      width: 'auto',
    },
    textPrimary: {
      fontSize: 13,
      maxHeight: 38,
      display: 'block',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  })
);

export default withRouter(SingleCheckoutWizard);
