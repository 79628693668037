import * as React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import LoadingHeader from './LoadingHeader';
import ContentHeader from './ContentHeader';

interface IProps {
  imageUrl: string | null;
  title: string | undefined;
  ean: string | undefined;
}

export const Header = (props: IProps) => {
  const classes = useStyles();
  const hasData = props.imageUrl && props.title && props.ean;

  return (
    <div className={classes.headerContainer}>
      {hasData ? <ContentHeader ean={props.ean!} title={props.title!} imageUrl={props.imageUrl} /> : <LoadingHeader />}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerContainer: {
      padding: '3% 7%',
    },
  })
);
