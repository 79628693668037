import * as React from 'react';
import {
  CardContent,
  CardHeader,
  createStyles,
  Divider,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import priceRangeImage from './price-range.png';
import { BaseCard } from '../../common/components/BaseCard';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import DialogButton from '../../common/components/DialogButton';
import { searchHooks } from '@luso/common/src/pages/search';

const PriceCard = () => {
  const classes = useStyles();

  const priceRange = searchHooks.usePriceRange();
  const offers = searchHooks.useOffers();

  const infoButton = (
    <DialogButton
      icon={<HelpOutlineIcon />}
      dialogTitle={'Wie entsteht der Preis?'}
      dialogContent={
        <React.Fragment>
          <Typography gutterBottom>
            Wir kaufen die Artikel nicht selbst an, sondern zeigen dir lediglich die Ankaufpreise der größten deutschen
            Ankaufportale. Wir haben keinen Einfluss auf die genannten Preise.
          </Typography>
          <Typography>
            Die Erfahrung zeigt, dass Fachbücher mit aktuelleren Auflagen, alte Taschenbücher und Musik-CDs aus den 90er
            Jahren heute leider kaum mehr gefragt sind. Halbwegs aktuelle Bestseller, relevante Fachbücher und Spiele
            für moderne Konsolen erzielen dagegen bis zu 60% des Neupreises.
          </Typography>
        </React.Fragment>
      }
    />
  );

  return (
    <BaseCard>
      <CardHeader
        avatar={<img alt={''} className={classes.img} src={priceRangeImage} />}
        title={'Ankaufpreise'}
        titleTypographyProps={{ variant: 'caption', color: 'textSecondary' }}
        subheader={`${priceRange.minPrice} – ${priceRange.maxPrice}`}
        subheaderTypographyProps={{ variant: 'h5', color: 'textPrimary' }}
        action={infoButton}
      />
      <Divider />
      <CardContent className={classes.cardContent}>
        <List dense={true} className={classes.list}>
          {Object.keys(offers).map((k, index) => (
            <ListItem key={k} className={classes.listItem} divider={index < Object.keys(offers).length - 1}>
              <ListItemText className={classes.vendor} primary={k} secondary={offers[k].requestDate} />
              <ListItemText className={classes.price}>{offers[k].price}</ListItemText>
            </ListItem>
          ))}
        </List>
      </CardContent>
    </BaseCard>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardContent: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 2),
      paddingBottom: theme.spacing(0),
      '&:last-child': {
        paddingBottom: theme.spacing(1),
      },
    },
    img: {
      maxWidth: 50,
    },
    info: {
      marginLeft: theme.spacing(4),
    },
    topSection: {
      marginBottom: theme.spacing(2),
    },
    list: {
      width: '100%',
    },
    listItem: {
      display: 'flex',
    },
    vendor: {
      flexGrow: 1,
    },
    price: {
      flexGrow: 0,
    },
  })
);

export default PriceCard;
