import { CircularProgress, createStyles, makeStyles, Theme } from '@material-ui/core';
import * as React from 'react';
import ScanView from './ScanView';
import InstallationProposal from './InstallationProposal';

interface Props {
  hasCamera?: boolean;
  onBarcode?: (barcode: string) => void;
}

const ScanTab = (props: Props) => {
  const classes = useStyles();

  if (props.hasCamera === true) {
    return <ScanView onBarcode={props.onBarcode} />;
  } else if (props.hasCamera === false) {
    return <InstallationProposal />;
  } else {
    return (
      <div className={classes.container}>
        <CircularProgress />
      </div>
    );
  }
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
  })
);

export default ScanTab;
