import { createAction, createAsyncAction } from 'typesafe-actions';
import { IShoppingCartEntry, IVendor } from './models';
import { ApiError } from '../services/api/apiError';

const fetchVendors = createAsyncAction(
  '@common/FETCH_VENDORS_REQUEST',
  '@common/FETCH_VENDORS_SUCCESS',
  '@common/FETCH_VENDORS_FAILURE'
)<void, Record<string, IVendor>, ApiError>();

const addShoppingCartEntry = createAction('@common/ADD_SHOPPING_CART_ENTRY')<IShoppingCartEntry>();
const replaceShoppingCartEntries = createAction('@common/REPLACE_SHOPPING_CART_ENTRIES')<IShoppingCartEntry[]>();
const removeShoppingCartEntries = createAction('@common/REMOVE_SHOPPING_CART_ENTRIES')<IShoppingCartEntry[]>();

const disableFirstLaunch = createAction('@common/DISABLE_FIRST_LAUNCH')();

const snackbar = {
  show: createAction('@common/SHOW_SNACKBAR')<string>(),
  dismiss: createAction('@common/DISMISS_SNACKBAR')(),
};

export default {
  fetchVendors,
  addShoppingCartEntry,
  replaceShoppingCartEntries,
  removeShoppingCartEntries,
  disableFirstLaunch,
  snackbar,
};
