import * as React from 'react';
import { ListChildComponentProps } from 'react-window';
import SellableProductsHeader from './SellableProductsHeader';
import ProductListItem from './ProductListItem';
import UnsellableProductsHeader from './UnsellableProductsHeader';
import { isProductListItem, isSellableProductsHeader, ListItemType } from '@luso/common/src/pages/partition/models';

const PartitionListItem = (props: ListChildComponentProps) => {
  const listData = props.data as ListItemType[];
  const item = listData[props.index];

  if (isSellableProductsHeader(item)) {
    return <SellableProductsHeader style={props.style} {...item} />;
  } else if (isProductListItem(item)) {
    return <ProductListItem style={props.style} {...item} />;
  } else {
    return <UnsellableProductsHeader style={props.style} {...item} />;
  }
};

export default PartitionListItem;
