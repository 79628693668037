import axios, { AxiosInstance } from 'axios';
import UserAgent from 'user-agents';
import { ApiError } from '../api/apiError';
import { IMomoxConfig, IVendorProduct } from '../../common/models';
import { Api } from '../api/api';
import { ErrorLogger } from '../errorLogger/errorLogger';

export class MomoxAdapter {
  private readonly axiosInstance: AxiosInstance;
  private momoxConfig: IMomoxConfig | null | undefined;

  constructor(private readonly api: Api, private readonly errorLogger: ErrorLogger) {
    this.momoxConfig = undefined;
    this.axiosInstance = axios.create({
      headers: {
        'User-Agent': new UserAgent({ deviceCategory: 'mobile' }).data.userAgent,
      },
    });
  }

  async fetchProduct(ean: string): Promise<IVendorProduct> {
    let product;
    try {
      product = await this.fetchProductLocally(ean);

      await this.api.upsertLocalOffer({
        product: product
          ? {
            productName: product.name,
            imageUrl: product.imageUrl,
            priceCents: product.priceVeryGood,
          }
          : null,
        ean,
        isPurchasing: !!product,
        vendorName: 'momox',
      });
    } catch (e) {
      this.errorLogger.logFetchLocalMomoxError(e);
      return this.api.fetchProduct('momox', ean);
    }

    if (!product) {
      throw new ApiError('NotFound', 'Momox does not purchase this product');
    }
    return product;
  }

  private async fetchProductLocally(ean: string): Promise<IVendorProduct | null> {
    if (this.momoxConfig === undefined) {
      this.momoxConfig = await this.fetchMomoxConfig();
    }

    if (this.momoxConfig === null) {
      throw new ApiError('MomoxAdapter', 'MomoxConfig is not available');
    }

    const { data } = await this.axiosInstance.get(`/media/offer/?ean=${ean}`, {
      baseURL: this.momoxConfig.apiUrl,
      headers: {
        'x-api-token': this.momoxConfig.clientKey,
        'x-marketplace-id': this.momoxConfig.marketplaceId,
      },
    });

    if (data.status !== 'offer') return null;

    const price = Math.round(parseFloat(data.price) * 100);

    return {
      ean: data.product.ean,
      name: data.product.title,
      imageUrl: data.product.image_url,
      priceAcceptable: price,
      priceGood: price,
      priceVeryGood: price,
      priceLikeNew: price,
      requestDate: new Date(),
      vendorName: 'momox',
    };
  }

  private fetchMomoxConfig(): Promise<IMomoxConfig> {
    return this.api.fetchMomoxConfig();
  }
}
