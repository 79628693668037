import { createAction } from 'typesafe-actions';
import { IVendorProduct, ProductCondition } from '../../common/models';
import { ApiError } from '../../services/api/apiError';

const fetchProducts = {
  request: createAction('@search/FETCH_PRODUCTS_REQUEST')<string>(),
  productReceived: createAction('@search/FETCH_PRODUCTS_RECEIVED_PRODUCT')<{
    vendor: string;
    result: ApiError | IVendorProduct;
  }>(),
  completed: createAction('@search/FETCH_PRODUCTS_COMPLETED')<void>(),
};

const confirmPriceInfoBanner = createAction('@search/CONFIRM_PRICE_INFO_BANNER')();
const selectProductCondition = createAction('@search/SELECT_CONDITION')<ProductCondition>();

export default {
  fetchProducts,
  confirmPriceInfoBanner,
  selectProductCondition,
};
