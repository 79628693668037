import { RootState } from '../../createStore';
import { createSelector } from 'reselect';
import { priceForCondition } from '../../common/models';
import { normalize } from '../../utils/objUtils';
import { commonSelectors } from '../../common';
import { IOffer, IPriceRange } from './models';
import { mapObject } from '../../utils/mapUtils';
import { formatMoney, relativeDate } from '../../utils/formatter';

const selectIsLoading = (state: RootState) => state.search.isLoading;
const selectProducts = (state: RootState) => state.search.products;
const selectCondition = (state: RootState) => state.search.condition;
const selectShowPriceInfoBanner = (state: RootState) => state.search.showPriceInfoBanner;
const selectErrors = (state: RootState) => state.search.errors;

const selectTitle = createSelector(selectProducts, products => products[0]?.name);
const selectEan = createSelector(selectProducts, products => products[0]?.ean);
const selectImageUrl = createSelector(selectProducts, products => products.find(p => p.imageUrl)?.imageUrl);
const selectErrorMessage = createSelector(
  selectIsLoading,
  selectErrors,
  selectProducts,
  (isLoading, errors, products) => {
    if (!isLoading) {
      if (Object.values(errors).some(e => e.name === 'NetworkError')) {
        return 'Es konnte keine Verbindung zum Server hergestellt werden. Bitte überprüfe die Internetverbindung oder versuche es später noch einmal.';
      }

      if (products.length === 0 && Object.values(errors).length > 0) {
        if (Object.values(errors).some(e => e.name === 'NotFound')) {
          return 'Keine der angefragten Recommerce-Plattformen möchte diesen Artikel ankaufen. Versuche es mit einem anderen Artikel.';
        } else {
          return 'Bei der Preisabfrage sind Fehler aufgetreten. Bitte versuche es später noch einmal.';
        }
      }
    }
  }
);

const selectPriceRange = createSelector(
  selectProducts,
  selectCondition,
  (products, condition): IPriceRange => {
    const prices = products.map(product => priceForCondition(product, condition));
    return mapObject(
      {
        minPrice: Math.min(...prices),
        maxPrice: Math.max(...prices),
      },
      formatMoney
    );
  }
);

const selectOffersSortedByPrice = createSelector(
  selectProducts,
  commonSelectors.selectVendors,
  selectCondition,
  (products, vendors, condition): Record<string, IOffer> => {
    const offers = products
      .map(p => ({
        displayName: vendors[p.vendorName]?.displayName ?? p.vendorName,
        price: priceForCondition(p, condition),
        requestDate: p.requestDate,
      }))
      .sortedBy('price', 'DESC')
      .map(offer => ({
        ...offer,
        price: formatMoney(offer.price),
        requestDate: relativeDate(offer.requestDate),
      }));
    return normalize(offers, 'displayName');
  }
);

export default {
  selectIsLoading,
  selectProducts,
  selectCondition,
  selectShowPriceInfoBanner,
  selectTitle,
  selectEan,
  selectImageUrl,
  selectErrorMessage,
  selectPriceRange,
  selectOffersSortedByPrice,
};
