import { getType } from 'typesafe-actions';
import { put, select, takeLatest, takeLeading } from 'redux-saga/effects';
import actions from './actions';
import { commonActions, commonSelectors } from '../../common';
import { IPartition, IPartitionConfiguration, IShoppingCartEntry, IVendor } from '../../common/models';
import { takeVendors } from '../../common/sagas';
import { Api } from '../../services/api/api';
import { getService } from '../../services';
import { ApiError } from '../../services/api/apiError';
import selectors from './selectors';
import { ShareFunction } from './ShareService';
import baseConfig from '../../common-config.json';
import { settingsActions } from '../settings';
import { RootState } from '../../createStore';

const fetchPartitionSaga = takeLatest(getType(actions.fetchPartition.request), function* () {
  const [_, vendorError]: [Record<string, IVendor> | null, ApiError | null] = yield takeVendors();
  if (vendorError === null) {
    const api: Api = yield getService('api');
    const state: RootState = yield select();

    const { shoppingCartEntries, vendorNames, maxNumberOfParcels, shippingCost } = selectors.selectCurrentSearchParams(
      state,
    );

    try {
      if (shoppingCartEntries.length === 0) {
        throw new ApiError(
          'EmptyCart',
          'Die Verkaufsbox ist leer. Füge einige Artikel hinzu, bevor Du die Verteilung berechnen lässt.',
        );
      }

      const partition: IPartition = yield api.fetchPartition(
        shoppingCartEntries,
        vendorNames,
        maxNumberOfParcels,
        shippingCost,
      );
      yield put(
        actions.fetchPartition.success(partition, {
          shoppingCartEntries,
          vendorNames,
          maxNumberOfParcels,
          shippingCost,
        }),
      );
    } catch (e) {
      console.log('partition error', e);
      yield put(actions.fetchPartition.failure(e as ApiError));
    }
  } else {
    yield put(actions.fetchPartition.failure(vendorError));
  }
});

const loadPartitionSaga = takeLeading(getType(actions.loadPartition.request), function* (
  action: ReturnType<typeof actions.loadPartition.request>,
) {
  const api: Api = yield getService('api');
  const vendors = commonSelectors.selectVendors(yield select());

  try {
    const partitionConfig: IPartitionConfiguration = yield api.fetchSharedPartition(action.payload);
    const excludedVendors = Object.keys(vendors).filter(name => partitionConfig.vendors.indexOf(name) < 0);

    yield put(actions.loadPartition.success());
    yield put(commonActions.replaceShoppingCartEntries(partitionConfig.eansWithConditions));
    yield put(settingsActions.setShippingCost(partitionConfig.customShippingFee));
    yield put(settingsActions.setNumberOfParcels(partitionConfig.maxParcelCount));
    yield put(settingsActions.overrideExcludedVendors(excludedVendors));

    // trigger new fetch
    yield put(actions.fetchPartition.request());
  } catch (e) {
    yield put(actions.loadPartition.failure(e as ApiError));
  }
});

const deleteProductsSaga = takeLatest(getType(actions.deleteSelectedProducts), function* () {
  const selectedProducts = selectors.selectSelectedProducts(yield select());
  const shoppingCartEntries: IShoppingCartEntry[] = selectedProducts.map(p => ({
    condition: p.condition,
    ean: p.ean,
  }));

  yield put(actions.cancelActionMode());
  yield put(commonActions.removeShoppingCartEntries(shoppingCartEntries));
});

const sharePartitionSaga = takeLeading(getType(actions.savePartition.request), function* () {
  const api: Api = yield getService('api');
  const share: ShareFunction = yield getService('share');

  const state: RootState = yield select();
  const { shoppingCartEntries, vendorNames, maxNumberOfParcels, shippingCost } = selectors.selectCurrentSearchParams(
    state,
  );

  try {
    const partitionId: string = yield api.saveShoppingCartEntries(
      shoppingCartEntries,
      vendorNames,
      maxNumberOfParcels,
      shippingCost,
    );
    yield put(actions.savePartition.success());
    yield share(
      `Deine Artikelverteilung kannst du in jedem Browser wieder öffnen: ${baseConfig.appUrl}/partition/${partitionId}`,
    );
  } catch (e) {
    yield put(actions.savePartition.failure(e as ApiError));
    yield put(commonActions.snackbar.show((e as ApiError).message));
  }
});

export default [fetchPartitionSaga, loadPartitionSaga, deleteProductsSaga, sharePartitionSaga];
