import { HttpApi } from './httpApi';
import {
  ILocalOffer,
  IMomoxConfig,
  IPartition,
  IPartitionConfiguration,
  IShoppingCartEntry,
  IVendor,
  IVendorProduct,
} from '../../common/models';
import { stringify } from 'qs';

export class Api {
  private httpApi: HttpApi;

  constructor(httpApi: HttpApi) {
    this.httpApi = httpApi;
  }

  public async fetchProduct(vendorName: string, ean: string): Promise<IVendorProduct> {
    return this.httpApi.get(`/api/product?ean=${ean}&vendor=${vendorName}`);
  }

  public async fetchVendors(): Promise<Record<string, IVendor>> {
    return this.httpApi.get('/api/vendorinfo');
  }

  public async fetchPartition(
    shoppingCartEntries: IShoppingCartEntry[],
    vendors: string[],
    maxNumberOfParcels: number,
    shippingCost: number
  ): Promise<IPartition> {
    const queryParams = {
      vendors: vendors.join(','),
      maxParcelCount: maxNumberOfParcels.toString(),
      customShippingFee: shippingCost.toString(),
    };
    const body = stringify({
      eans: shoppingCartEntries.map(e => e.ean).join(','),
      conditions: shoppingCartEntries.map(e => e.condition).join(','),
    });

    return await this.httpApi.post<IPartition>('/api/partition', body, {
      params: queryParams,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  }

  public sendFeedback(feedback: {
    numberOfStars: number;
    platform: string;
    message: string;
    email: string;
  }): Promise<void> {
    return this.httpApi.post('/api/feedback', feedback);
  }

  public async fetchSharedPartition(id: string): Promise<IPartitionConfiguration> {
    return await this.httpApi.get(`/api/partition/save/${id}`);
  }

  public async saveShoppingCartEntries(
    items: IShoppingCartEntry[],
    vendors: string[],
    maxNumberOfParcels: number,
    shippingCost: number
  ): Promise<string> {
    const queryParams = {
      vendors: vendors.join(','),
      maxParcelCount: maxNumberOfParcels.toString(),
      customShippingFee: shippingCost.toString(),
    };
    const responseData = await this.httpApi.post<{ shareId: string }>(
      '/api/partition/save',
      stringify({
        eans: items.map(e => e.ean).join(','),
        conditions: items.map(e => e.condition).join(','),
      }),
      {
        params: queryParams,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      }
    );
    return responseData.shareId;
  }

  public upsertLocalOffer(offer: ILocalOffer): Promise<void> {
    return this.httpApi.post('/api/offers', offer);
  }

  public fetchMomoxConfig(): Promise<IMomoxConfig> {
    return this.httpApi.get<IMomoxConfig>('/api/vendorinfo/momox-config');
  }

  public logError(platform: string, errorName: string, errorMessage?: string): Promise<void> {
    return this.httpApi.post('/api/client-errors', {
      platform,
      errorName,
      errorMessage,
    });
  }
}
