import '../utils/arrayUtils';

export interface IVendorProduct {
  name: string;
  priceLikeNew: number;
  priceVeryGood: number;
  priceGood: number;
  priceAcceptable: number;
  ean: string;
  imageUrl?: string;
  vendorName: string;
  requestDate: Date;
}

export interface IVendor {
  name: string;
  displayName: string;
  minPurchase: number;
  shippingCost: IShippingCost[];
  eanSeparator?: string;
  bulkTransferUrl?: string;
  singleTransferUrl?: string;
  autoTransferUrl?: string;
}

export interface IShippingCost {
  threshold: number;
  cost: number;
}

export interface IShoppingCartEntry {
  ean: string;
  condition: ProductCondition;
}

export interface IPartitionProduct {
  name: string;
  ean: string;
  price: number;
  imageUrl?: string;
  vendorName: string;
  requestDate: Date;
  condition: ProductCondition;
  indexedEan: string;
}

export interface IPartition {
  partition: Record<string, IPartitionProduct[]>;
  unsellableProducts: IPartitionProduct[];
}

export interface IMomoxConfig {
  apiUrl: string;
  clientKey: string;
  marketplaceId: string;
}

export enum ProductCondition {
  LikeNew = 'likeNew',
  VeryGood = 'veryGood',
  Good = 'good',
  Acceptable = 'acceptable',
}

export interface IPartitionConfiguration {
  identifier: string;
  eansWithConditions: IShoppingCartEntry[];
  vendors: string;
  maxParcelCount: number;
  customShippingFee: number;
}

export interface ILocalOffer {
  ean: string;
  vendorName: string;
  isPurchasing: boolean;
  product: {
    imageUrl?: string;
    productName: string;
    priceCents: number;
  } | null;
}

export const productConditionToStringMap = new Map([
  [ProductCondition.LikeNew, 'Wie neu'],
  [ProductCondition.VeryGood, 'Sehr gut'],
  [ProductCondition.Good, 'Gut'],
  [ProductCondition.Acceptable, 'Akzeptabel'],
]);

export function priceForCondition(product: IVendorProduct, condition: ProductCondition) {
  switch (condition) {
    case ProductCondition.LikeNew:
      return product.priceLikeNew;
    case ProductCondition.VeryGood:
      return product.priceVeryGood;
    case ProductCondition.Good:
      return product.priceGood;
    case ProductCondition.Acceptable:
      return product.priceAcceptable;
  }
}

export function calculateShippingCost(vendor: IVendor, priceInCent: number, defaultCost: number): number {
  const sc = vendor.shippingCost
    .slice()
    .reverse()
    .find(sc => sc.threshold <= priceInCent);
  return sc ? sc.cost : defaultCost;
}

export function sumProductPrices(products: IPartitionProduct[]) {
  return products.sumBy('price');
}

export function calculateTotalPrice(
  partition: IPartition,
  vendors: Record<string, IVendor>,
  defaultShippingCost: number
): number {
  return Object.entries(partition.partition)
    .map(([vendorName, products]) => {
      const revenue = sumProductPrices(products);
      const shippingCost = calculateShippingCost(vendors[vendorName], revenue, defaultShippingCost);
      return revenue - shippingCost;
    })
    .sum();
}

export function getAllProductsInPartition(partition: IPartition): IPartitionProduct[] {
  const regularProducts = Object.values(partition.partition).reduce((acc, curr) => {
    return acc.concat(curr);
  }, []);

  return regularProducts.concat(partition.unsellableProducts);
}

export function areShoppingCartEntriesEqual(entry1: IShoppingCartEntry, entry2: IShoppingCartEntry): boolean {
  return entry1.condition === entry2.condition && entry1.ean === entry2.ean;
}
