import { createAction } from 'typesafe-actions';

const setShippingCost = createAction('@settings/SET_SHIPPING_COST')<number>();
const setNumberOfParcels = createAction('@settings/SET_NO_OF_PARCELS')<number>();
const toggleVendor = createAction('@settings/TOGGLE_VENDOR')<string, boolean>();
const overrideExcludedVendors = createAction('@setting/OVERRIDE_EXCLUDED_VENDORS')<string[]>();

export default {
  setShippingCost,
  setNumberOfParcels,
  toggleVendor,
  overrideExcludedVendors,
};
