import * as React from 'react';
import { createStyles, IconButton, makeStyles, Theme } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';

interface IProps {
  handleBackClicked: () => void;
}

const BackButton = (props: IProps) => {
  const classes = useStyles();

  return (
    <IconButton className={classes.button} color="inherit" aria-label="Back" onClick={props.handleBackClicked}>
      <ArrowBack />
    </IconButton>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginLeft: theme.spacing(-2),
    },
  })
);

export default BackButton;
