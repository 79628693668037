import { createReducer } from 'typesafe-actions';
import produce from 'immer';
import actions from './actions';
import { getAllProductsInPartition, IPartition } from '../../common/models';
import { ApiError } from '../../services/api/apiError';
import { RootAction } from '../../createStore';
import { SearchParams } from './models';

export interface PartitionState {
  partition?: IPartition;
  isLoading: boolean;
  error?: ApiError;
  shareError?: ApiError;
  selectedIndexedEans: string[];
  isInActionMode: boolean;
  showFeedbackDate: number | null;
  searchParams?: SearchParams;
  showOverrideDialog: boolean;
}

const defaultState: PartitionState = {
  isLoading: false,
  selectedIndexedEans: [],
  isInActionMode: false,
  showFeedbackDate: createFutureDate(10), //first show 10 minutes after start.
  showOverrideDialog: false,
};

export const partitionWhitelist: (keyof PartitionState)[] = ['showFeedbackDate'];

const reducer = createReducer<PartitionState, RootAction>(defaultState)
  .handleAction(actions.fetchPartition.request, (state, action) =>
    produce(state, draft => {
      draft.isLoading = true;
      draft.error = undefined;
      draft.selectedIndexedEans = [];
    })
  )
  .handleAction(actions.fetchPartition.success, (state, action) =>
    produce(state, draft => {
      draft.isLoading = false;
      draft.partition = action.payload;
      draft.searchParams = action.meta;
    })
  )
  .handleAction(actions.fetchPartition.failure, (state, action) =>
    produce(state, draft => {
      draft.isLoading = false;
      draft.error = action.payload;
      draft.searchParams = undefined;
      draft.partition = undefined;
    })
  )
  .handleAction(actions.enterActionMode, (state, action) =>
    produce(state, draft => {
      draft.isInActionMode = true;
    })
  )
  .handleAction(actions.cancelActionMode, (state, action) =>
    produce(state, draft => {
      draft.isInActionMode = false;
      draft.selectedIndexedEans = [];
    })
  )
  .handleAction(actions.hideFeedbackBannerForDays, (state, action) =>
    produce(state, draft => {
      draft.showFeedbackDate = action.payload ? createFutureDate(action.payload * 60 * 24) : null;
    })
  )
  .handleAction(actions.toggleItem, (state, action) =>
    produce(state, draft => {
      const indexedEan = action.payload;
      if (state.selectedIndexedEans.includes(indexedEan)) {
        draft.selectedIndexedEans = state.selectedIndexedEans.filter(ie => ie !== indexedEan);
      } else {
        draft.selectedIndexedEans.push(indexedEan);
      }
    })
  )
  .handleAction(actions.toggleAll, (state, action) =>
    produce(state, draft => {
      if (!state.partition) return;

      const productsInPartition = getAllProductsInPartition(state.partition);

      if (state.selectedIndexedEans.length < productsInPartition.length) {
        draft.selectedIndexedEans = productsInPartition.map(p => p.indexedEan);
      } else {
        draft.selectedIndexedEans = [];
      }
    })
  )
  .handleAction(actions.savePartition.request, (state, action) =>
    produce(state, draft => {
      draft.isLoading = true;
    })
  )
  .handleAction(actions.savePartition.success, (state, action) =>
    produce(state, draft => {
      draft.isLoading = false;
    })
  )
  .handleAction(actions.savePartition.failure, (state, action) =>
    produce(state, draft => {
      draft.isLoading = false;
      draft.shareError = action.payload;
    })
  )
  .handleAction(actions.loadPartition.request, (state, action) =>
    produce(state, draft => {
      draft.isLoading = true;
      draft.error = undefined;
      draft.selectedIndexedEans = [];
    })
  )
  .handleAction(actions.loadPartition.success, (state, action) =>
    produce(state, draft => {
      draft.isLoading = false;
    })
  )
  .handleAction(actions.loadPartition.failure, (state, action) =>
    produce(state, draft => {
      draft.isLoading = false;
      draft.error = action.payload;
    })
  );

export default reducer;

function createFutureDate(inMinutes: number, now = Date.now()) {
  return now + inMinutes * 60000;
}
