import React from 'react';
import { Container, createStyles, makeStyles, Theme } from '@material-ui/core';
import AppRouter from './Router';
import ThemeProvider from './ThemeProvider';
import { configureStore } from '@luso/common/src/createStore';
import localForage from 'localforage';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

const services = {
  storage: localForage,
  platform: 'web',
};

const checkIfCameraIsAvailable = async () => {
  try {
    await navigator.mediaDevices.getUserMedia({ video: true });
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
};
// outside of component, because checking too often causes errors
(window as any).hasCameraPromise = checkIfCameraIsAvailable();

function App() {
  const classes = useStyles();

  const [store, persistor] = configureStore(services);

  return (
    <Provider store={store}>
      <Container className={classes.container} maxWidth="md">
        <ThemeProvider>
          <PersistGate persistor={persistor}>
            <AppRouter />
          </PersistGate>
        </ThemeProvider>
      </Container>
    </Provider>
  );
}

const useStyles = makeStyles(({ breakpoints }: Theme) =>
  createStyles({
    container: {
      height: '100%',
      [breakpoints.down('md')]: {
        padding: 0,
      },
    },
  })
);

export default App;
