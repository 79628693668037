import { ICommonDependencies, IDependencies, ILocalDependencies } from './services';
import createSagaMiddleware from 'redux-saga';
import { Api } from './services/api/api';
import { MomoxAdapter } from './services/momox/momoxAdapter';
import baseConfig from './common-config.json';
import { HttpApi } from './services/api/httpApi';
import { applyMiddleware, compose, createStore, Store } from 'redux';
import { searchActions, searchSagas } from './pages/search';
import { all } from 'redux-saga/effects';
import { ActionType, StateType } from 'typesafe-actions';
import { commonActions, commonSagas } from './common';
import { partitionActions, partitionSagas } from './pages/partition';
import { feedbackActions, feedbackSagas } from './pages/feedback';
import { settingsActions } from './pages/settings';
import { createPersistedReducer, createRootReducer } from './persistReducer';
import { persistStore } from 'redux-persist';
import { Persistor } from 'redux-persist/es/types';
import { ErrorLogger } from './services/errorLogger/errorLogger';

const dev = process.env.NODE_ENV === 'development';

export function configureStore(localDependencies: ILocalDependencies): [Store, Persistor] {
  const rootReducer = createPersistedReducer(localDependencies.storage);

  const api = new Api(new HttpApi(baseConfig.baseUrl));
  const errorLogger = new ErrorLogger(api, localDependencies.platform);
  const commonDependencies: ICommonDependencies = {
    api,
    errorLogger,
    momoxAdapter: new MomoxAdapter(api, errorLogger),
  };

  const services: IDependencies = {
    ...commonDependencies,
    ...localDependencies,
  };

  const composeEnhancers = dev ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose;

  const sagaMiddleware = createSagaMiddleware({ context: services });

  const middlewares = [sagaMiddleware];
  if (process.env.NODE_ENV === `development`) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { logger } = require(`redux-logger`);
    middlewares.push(logger);
  }

  const store: Store = createStore(rootReducer, undefined, composeEnhancers(applyMiddleware(...middlewares)));

  const persistor = persistStore(store);

  sagaMiddleware.run(rootSaga);

  return [store, persistor];
}

function* rootSaga() {
  yield all([...commonSagas, ...searchSagas, ...partitionSagas, ...feedbackSagas]);
}

export type RootState = StateType<ReturnType<typeof createRootReducer>>;

export type RootAction =
  | ActionType<typeof commonActions>
  | ActionType<typeof searchActions>
  | ActionType<typeof partitionActions>
  | ActionType<typeof feedbackActions>
  | ActionType<typeof settingsActions>;
