import { useDispatch, useSelector } from 'react-redux';
import selectors from './selectors';
import actions from './actions';

export const useSettingsState = () => useSelector(selectors.selectSettingsState);
export const useExcludedVendors = () => useSelector(selectors.selectExcludedVendors);
export const useActiveVendorCount = () => useSelector(selectors.countActiveVendors);
export const useMaxParcelCount = () => useSelector(selectors.selectMaxNumberOfParcels);
export const useShippingCost = () => useSelector(selectors.selectCustomShippingFee);
export const useSelectedVendorVMs = () => useSelector(selectors.selectSelectedVendorVMs);
export const useAreDefaultSettings = () => useSelector(selectors.areDefaultSettings);

export function useSetParcelCount() {
  const dispatch = useDispatch();
  return (parcelCount: number) => dispatch(actions.setNumberOfParcels(parcelCount));
}

export function useSetShippingCost() {
  const dispatch = useDispatch();
  return (shippingCost: number) => dispatch(actions.setShippingCost(shippingCost));
}

export function useToggleVendor() {
  const dispatch = useDispatch();
  return (vendorName: string, isSelected: boolean) => dispatch(actions.toggleVendor(vendorName, isSelected));
}
