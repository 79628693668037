import { Breakpoints } from '@material-ui/core/styles/createBreakpoints';

type fontSize = number | string | undefined;

export const responsiveCss = (
  breakpoints: Breakpoints,
  property: string,
  defaultSize: number | string,
  sm: fontSize = defaultSize,
  md: fontSize = sm,
  lg: fontSize = md
) => ({
  [property]: defaultSize,
  [breakpoints.up('sm')]: { [property]: sm },
  [breakpoints.up('md')]: { [property]: md },
  [breakpoints.up('lg')]: { [property]: lg },
});

export const responsiveFontSize = (
  breakpoints: Breakpoints,
  defaultSize: number | string,
  sm: fontSize = defaultSize,
  md: fontSize = sm,
  lg: fontSize = md
) => responsiveCss(breakpoints, 'fontSize', defaultSize, sm, md, lg);
