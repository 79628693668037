import { RootState } from '../../createStore';
import { createSelector } from 'reselect';
import { commonSelectors } from '../../common';
import { createSelectedVendorVMs, defaultSettingsState } from './models';

const selectSettingsState = (state: RootState) => state.settings;
const selectCustomShippingFee = (state: RootState) => state.settings.customShippingFee;

const selectMaxNumberOfParcels = createSelector(
  commonSelectors.selectVendors,
  (state: RootState) => state.settings.maxNumberOfParcels,
  (vendors, parcelCount) => {
    return parcelCount ?? Object.values(vendors).length;
  }
);

const selectExcludedVendors = (state: RootState) => state.settings.excludedVendors;

const selectActiveVendorNames = createSelector(
  commonSelectors.selectVendors,
  selectExcludedVendors,
  (vendors, excludedVendors) => {
    return Object.entries(vendors)
      .filter(([vendorName]) => !excludedVendors.includes(vendorName))
      .map(([vendorName]) => vendorName);
  }
);

const countActiveVendors = createSelector(selectActiveVendorNames, activeVendorNames => activeVendorNames.length);
const selectSelectedVendorVMs = createSelector(
  commonSelectors.selectVendors,
  selectExcludedVendors,
  createSelectedVendorVMs
);
const areDefaultSettings = createSelector(
  selectSettingsState,
  commonSelectors.selectNumberOfVendors,
  (settingsState, numberOfVendors) => {
    if (settingsState.maxNumberOfParcels !== undefined && settingsState.maxNumberOfParcels !== numberOfVendors) {
      return false;
    }

    if (settingsState.excludedVendors.length > defaultSettingsState.excludedVendors.length) {
      return false;
    }

    if (settingsState.customShippingFee !== defaultSettingsState.customShippingFee) {
      return false;
    }

    return true;
  }
);

export default {
  selectSettingsState,
  selectCustomShippingFee,
  selectMaxNumberOfParcels,
  selectExcludedVendors,
  selectActiveVendorNames,
  selectSelectedVendorVMs,
  countActiveVendors,
  areDefaultSettings,
};
