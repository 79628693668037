import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import classNames from 'classnames';

interface Props {
  smDown?: true;
  mdUp?: true;
  children: React.ReactNode;
}

export const Hidden = (props: Props) => {
  const classes = useStyles();
  return (
    <div
      className={classNames({
        [classes.smDown]: props.smDown,
        [classes.mdUp]: props.mdUp,
      })}
    >
      {props.children}
    </div>
  );
};

const useStyles = makeStyles(theme =>
  createStyles({
    smDown: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    mdUp: {
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
  })
);
