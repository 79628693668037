import * as React from 'react';
import { createStyles, Dialog, DialogTitle, List, ListItem, ListItemText, makeStyles, Theme } from '@material-ui/core';
import Checkbox from '@material-ui/icons/CheckBox';
import CheckboxBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import { settingsHooks } from '@luso/common/src/pages/settings';

interface IOwnProps {
  visible: boolean;
  onDismiss: () => void;
}

type IProps = IOwnProps;

const VendorSelectionDialog = (props: IProps) => {
  const classes = useStyles();

  const selectedVendorVMs = settingsHooks.useSelectedVendorVMs();
  const toggleVendor = settingsHooks.useToggleVendor();

  return (
    <Dialog open={props.visible} onClose={props.onDismiss} aria-labelledby="vendor-dialog-title">
      <DialogTitle id={'vendor-dialog-title'}>Berücksichtigte Ankäufer</DialogTitle>
      <List>
        {selectedVendorVMs.map(v => (
          <ListItem key={v.vendorName} button divider onClick={() => toggleVendor(v.vendorName, !v.isChecked)}>
            {v.isChecked ? <Checkbox color={'secondary'} /> : <CheckboxBlank color={'secondary'} />}
            <ListItemText className={classes.text} primary={v.title} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    text: {
      marginLeft: theme.spacing(2),
    },
  })
);

export default VendorSelectionDialog;
