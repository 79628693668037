import { createSelector } from 'reselect';
import { commonSelectors } from '../../common';
import { partitionSelectors } from '../partition';
import { IBulkCheckout, ISingleCheckout, ISingleCheckoutProduct } from './models';
import { IPartitionProduct } from '../../common/models';
import { createSingleTransferUrl } from '../../utils/urlUtils';

const selectCheckoutVM = (vendorName: string) =>
  createSelector(commonSelectors.selectVendors, partitionSelectors.selectPartition, (vendors, partition) => {
    if (vendors && partition?.partition) {
      const vendor = vendors[vendorName];
      const products = partition?.partition[vendorName];

      if (vendor.bulkTransferUrl) {
        return {
          vendorDisplayName: vendor.displayName,
          eans: products.map(p => p.ean).join('\n'),
          checkoutUrl: encodeURI(vendor.bulkTransferUrl),
        } as IBulkCheckout;
      } else if (vendor.singleTransferUrl) {
        return {
          vendorDisplayName: vendor.displayName,
          products: products.map(
            (p: IPartitionProduct) =>
              ({
                ean: p.ean,
                name: p.name,
                imageUrl: p.imageUrl,
                checkoutUrl: createSingleTransferUrl(vendor.singleTransferUrl!, p.ean),
              } as ISingleCheckoutProduct)
          ),
        } as ISingleCheckout;
      }
    }
  });

export default {
  selectCheckoutVM,
};
