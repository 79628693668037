import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import * as React from 'react';
import MobileStoreButton from 'react-mobile-store-button';

interface Props {}

const InstallationProposal = (props: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography variant={'body2'} className={classes.text}>
        Dein Browser unterstützt den Barcodescanner nicht. Du kannst die Produktnummern entweder manuell eingeben, oder
        unsere native App für Smartphones installieren.
      </Typography>
      <MobileStoreButton
        store="ios"
        width={150}
        url={'https://apps.apple.com/de/app/sell4more-ankaufvergleich/id1435822175?mt=8'}
        linkProps={{ title: 'Sell4More im iOS-AppStore' }}
      />
      <MobileStoreButton
        store="android"
        width={150}
        url={'https://play.google.com/store/apps/details?id=com.lumindsolutions.sell4more'}
        linkProps={{ title: 'Sell4More im Google-PlayStore' }}
      />
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      flexGrow: 1,
    },
    text: {
      padding: theme.spacing(0, 2, 2, 2),
      textAlign: 'center',
    },
  })
);

export default InstallationProposal;
