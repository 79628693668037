import * as React from 'react';
import Banner from './Banner';
import AddIcon from '@material-ui/icons/AddToHomeScreen';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

interface Props {}

const InstallationBanner = (props: Props) => {
  const classes = useStyles();

  const [visible, setVisible] = React.useState(false);
  const [deferredPrompt, setDeferredPrompt] = React.useState<BeforeInstallPromptEvent | undefined>(undefined);

  const BEFORE_INSTALL_PROMPT = 'beforeinstallprompt';
  const APP_INSTALLED = 'appinstalled';

  React.useEffect(() => {
    const onBeforeInstallPrompt = async (evt: Event) => {
      evt.preventDefault();
      setDeferredPrompt(evt as BeforeInstallPromptEvent);
      setVisible(true);
    };

    const onAppInstalled = () => setVisible(false);

    window.addEventListener(BEFORE_INSTALL_PROMPT, onBeforeInstallPrompt);
    window.addEventListener(APP_INSTALLED, onAppInstalled);

    return () => {
      window.removeEventListener(BEFORE_INSTALL_PROMPT, onBeforeInstallPrompt);
      window.removeEventListener(APP_INSTALLED, onAppInstalled);
    };
  });

  return (
    <Banner
      open={visible}
      className={classes.banner}
      label={'Möchtest du die Sell4More-App installieren?'}
      dismissButtonLabel={'Abbrechen'}
      buttonLabel={'Installieren'}
      buttonOnClick={() => deferredPrompt!.prompt()}
      onClose={() => setVisible(false)}
      icon={<AddIcon />}
    />
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    banner: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 9,
    },
  })
);

interface BeforeInstallPromptEvent extends Event {
  /**
   * Returns an array of DOMString items containing the platforms on which the event was dispatched.
   * This is provided for user agents that want to present a choice of versions to the user such as,
   * for example, "web" or "play" which would allow the user to chose between a web version or
   * an Android version.
   */
  readonly platforms: Array<string>;

  /**
   * Returns a Promise that resolves to a DOMString containing either "accepted" or "dismissed".
   */
  readonly userChoice: Promise<{
    outcome: 'accepted' | 'dismissed';
    platform: string;
  }>;

  /**
   * Allows a developer to show the install prompt at a time of their own choosing.
   * This method returns a Promise.
   */
  prompt(): Promise<void>;
}

export default InstallationBanner;
