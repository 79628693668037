import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import { ApiError, NetworkError } from './apiError';

function isAxiosError(error: Error): error is AxiosError {
  return (error as AxiosError).response !== undefined;
}

export function isApiError(data: any): boolean {
  return data.name !== undefined && data.description !== undefined;
}

const dateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.*$/;

function dateReceiver(key: string, value: any) {
  if (key === 'requestDate' && typeof value === 'number') {
    return new Date(value);
  }
  return value;
}

export class HttpApi {
  private axiosInstance: AxiosInstance;

  constructor(baseURL: string) {
    this.axiosInstance = axios.create({
      baseURL,
      transformResponse: res => {
        if (res) {
          return JSON.parse(res, dateReceiver);
        } else {
          return res;
        }
      },
    });
    this.setupResponseInterceptor();
  }

  public async get<T = any>(url: string, config?: AxiosRequestConfig): Promise<T> {
    const result = await this.axiosInstance.get(url, config);
    return result.data;
  }

  public async post<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
    const result = await this.axiosInstance.post(url, data, config);
    return result.data;
  }

  private setupResponseInterceptor() {
    this.axiosInstance.interceptors.response.use(
      response => {
        return response;
      },
      error => {
        if (isAxiosError(error) && error.response !== undefined) {
          const data = error.response.data;
          if (isApiError(data)) {
            const apiError = new ApiError(data.name, data.description);
            return Promise.reject(apiError);
          } else {
            return Promise.reject(new ApiError(error.response.statusText, error.message));
          }
        } else {
          return Promise.reject(new NetworkError());
        }
      },
    );
  }
}
