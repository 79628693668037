import * as React from 'react';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const OverflowMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  return (
    <React.Fragment>
      <IconButton
        color="inherit"
        aria-label="Mehr"
        aria-controls="menu"
        aria-haspopup="true"
        onClick={event => setAnchorEl(event.currentTarget)}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu id="menu" anchorEl={anchorEl} keepMounted open={open} onClose={() => setAnchorEl(null)}>
        <li>
          <MenuItem
            component={'a'}
            href="https://sell4more.de/impressum.html"
            target="_blank"
            rel="noopener"
            key="imprint"
          >
            Impressum
          </MenuItem>
        </li>
        <li>
          <MenuItem
            component={'a'}
            href="https://sell4more.de/datenschutz.html"
            target="_blank"
            rel="noopener"
            key="privacy"
          >
            Datenschutz
          </MenuItem>
        </li>
      </Menu>
    </React.Fragment>
  );
};

export default OverflowMenu;
