import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import * as React from 'react';

interface Props {
  totalPrice: string;
}

const PartitionHeader = (props: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography variant={'caption'}>Geamterlös</Typography>
      <Typography variant={'h4'}>{props.totalPrice}</Typography>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      margin: 'auto',
      textAlign: 'center',
      padding: theme.spacing(1, 1, 3, 1),
    },
  })
);

export default PartitionHeader;
