import { RootState } from '../createStore';
import { createSelector } from 'reselect';
import { IShoppingCartEntry } from './models';

const selectVendors = (state: RootState) => state.common.vendors;
const selectNumberOfVendors = createSelector(selectVendors, vendors => Object.keys(vendors).length);
const selectIsFirstLaunch = (state: RootState) => state.common.isFirstLaunch;
const selectShoppingCartEntries = (state: RootState): IShoppingCartEntry[] => state.common.shoppingCartEntries;
const selectNumberOfShoppingCartEntries = createSelector(selectShoppingCartEntries, entries => entries.length);
const selectSnackbarContent = (state: RootState) => state.common.snackbarContent;
const selectHasProductsInCart = createSelector(selectNumberOfShoppingCartEntries, count => count > 0);

export default {
  selectVendors,
  selectNumberOfVendors,
  selectIsFirstLaunch,
  selectShoppingCartEntries,
  selectNumberOfShoppingCartEntries,
  selectSnackbarContent,
  selectHasProductsInCart,
};
