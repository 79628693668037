import * as React from 'react';
import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Typography,
} from '@material-ui/core';
import { Page } from '../common/components/Page';
import { RouteComponentProps } from 'react-router-dom';
import PartitionHeader from './Header/PartitionHeader';
import ErrorCard from '../common/components/ErrorCard';
import PartitionList from './List/PartitionList';

import PartitionActionBar from './Header/PartitionActionBar';
import BottomAppBar from './Header/BottomAppBar';
import { partitionHooks } from '@luso/common/src/pages/partition';
import { commonHooks } from '@luso/common/src/common';
import { push } from '../common/utils/routerUtil';

type IOwnProps = RouteComponentProps<{ id?: string }>;

const PartitionPage = (props: IOwnProps) => {
  const sections = partitionHooks.useSections();
  const totalPrice = partitionHooks.useTotalPrice();
  const isLoading = partitionHooks.useIsLoading();
  const error = partitionHooks.useError();
  const isInActionMode = partitionHooks.useIsInActionMode();
  const shouldFetchPartition = partitionHooks.useShouldFetchPartition();
  const hasProductsInCart = commonHooks.useHasProductsInCart();

  const fetchPartition = partitionHooks.useFetchPartition();
  const cancelActionMode = partitionHooks.useCancelActionMode();
  const toggleItem = partitionHooks.useToggleItem();
  const loadSharedPartition = partitionHooks.useLoadSharedPartition();

  const [isDialogOpen, setDialogOpen] = React.useState(false);

  React.useEffect(() => {
    const unblock = props.history.block(cancelActionMode);
    if (props.match.params.id) {
      if (hasProductsInCart) {
        // show confirmation dialog
        setDialogOpen(true);
      } else {
        // load directly
        loadSharedPartition(props.match.params.id);
      }
    } else if (shouldFetchPartition) {
      fetchPartition();
    }
    return unblock;
    // eslint-disable-next-line
  }, []);

  const onConfirmOverride = () => {
    setDialogOpen(false);
    if (props.match.params.id) {
      loadSharedPartition(props.match.params.id);
    }
  };

  const onRejectOverride = () => {
    setDialogOpen(false);
    push(props, '/partition');
    fetchPartition();
  };

  console.log(error);

  return (
    <Page>
      <AppBar position="relative">
        <PartitionActionBar {...props} />
        <PartitionHeader totalPrice={totalPrice} />
      </AppBar>
      {isLoading ? <LinearProgress color="secondary" /> : null}

      {!!error && <ErrorCard errorMessage={error.message} />}
      {!isLoading ? <PartitionList sections={sections} isInEditMode={isInActionMode} onToggle={toggleItem} /> : null}
      {isInActionMode && <BottomAppBar />}

      <Dialog open={isDialogOpen}>
        <DialogTitle>Verkaufbox überschreiben</DialogTitle>
        <DialogContent>
          <Typography>
            Du hast noch Artikel in deiner Verkaufsbox, die beim Laden der gespeicherten Produkte überschrieben werden.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={onRejectOverride}>Abbrechen</Button>
          <Button onClick={onConfirmOverride}>Weiter!</Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
};

export default PartitionPage;
