import { Badge, createStyles, IconButton, makeStyles, Theme, Toolbar, Typography } from '@material-ui/core';
import * as React from 'react';
import { backOrReplace } from '../../common/utils/routerUtil';
import BackButton from '../../common/components/BackButton';
import CloseIcon from '@material-ui/icons/Close';
import { RouteComponentProps } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';
import SettingsIcon from '@material-ui/icons/Settings';
import { withInternalLink } from '../../common/components/withLink';
import { partitionHooks } from '@luso/common/src/pages/partition/index';
import { settingsHooks } from '@luso/common/src/pages/settings';
import { commonHooks } from '@luso/common/src/common';

type IOwnProps = RouteComponentProps;

const LinkIconButton = withInternalLink(IconButton);

const PartitionActionBar = (props: IOwnProps) => {
  const classes = useStyles();

  const isPartitionEmpty = partitionHooks.useIsPartitionEmpty();

  const isInActionMode = partitionHooks.useIsInActionMode();
  const numberOfSelections = partitionHooks.useNumberOfSelections();
  const areDefaultSettings = settingsHooks.useAreDefaultSettings();
  const vendors = commonHooks.useVendors();
  const areSettingsDisabled = Object.keys(vendors).length === 0;

  const cancelActionMode = partitionHooks.useCancelActionMode();
  const enterActionMode = partitionHooks.useEnterActionMode();

  const StandardToolbar = () => (
    <Toolbar>
      <BackButton handleBackClicked={() => backOrReplace(props)} />
      <Typography className={classes.title} variant="h6" color="inherit">
        Aufteilung
      </Typography>
      <div>
        <IconButton disabled={isPartitionEmpty} color={'inherit'} onClick={enterActionMode}>
          <DeleteIcon />
        </IconButton>
        <LinkIconButton to={'/settings'} color={'inherit'} disabled={areSettingsDisabled}>
          <Badge invisible={areDefaultSettings} color="secondary" variant="dot">
            <SettingsIcon />
          </Badge>
        </LinkIconButton>
      </div>
    </Toolbar>
  );

  const ActionToolbar = () => (
    <Toolbar>
      <BackButton handleBackClicked={() => backOrReplace(props)} />
      <Typography className={classes.title} variant="h6" color="inherit">
        Auswahl ({numberOfSelections})
      </Typography>
      <div>
        <IconButton color={'inherit'} onClick={cancelActionMode}>
          <CloseIcon />
        </IconButton>
      </div>
    </Toolbar>
  );

  return isInActionMode ? <ActionToolbar /> : <StandardToolbar />;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      flexGrow: 1,
    },
  })
);

export default PartitionActionBar;
