import {
  Checkbox,
  createStyles,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Slide,
  Theme,
  Typography,
} from '@material-ui/core';
import * as React from 'react';
import { CSSProperties } from 'react';
import ImageWithFallback from '../../common/components/ImageWithFallback';
import { IProductListItem } from '@luso/common/src/pages/partition/models';
import { partitionHooks } from '@luso/common/src/pages/partition';

type IAllProps = IProductListItem & { style: CSSProperties };

const ProductListItem = (props: IAllProps) => {
  const classes = useStyles();

  const toggleItem = partitionHooks.useToggleItem();

  const onPress = () => toggleItem(props.indexedEan);

  return (
    <ListItem style={props.style} className={classes.item} divider={true}>
      <Slide direction="right" in={props.showsCheckBox} mountOnEnter unmountOnExit>
        <ListItemIcon>
          <Checkbox edge="start" checked={props.isSelected} onChange={onPress} disableRipple />
        </ListItemIcon>
      </Slide>
      <ListItemAvatar>
        <ImageWithFallback className={classes.image} url={props.imageUrl} />
      </ListItemAvatar>
      <ListItemText
        classes={{ primary: classes.textPrimary }}
        primary={props.title}
        primaryTypographyProps={{ variant: 'body2' }}
        secondary={`Zustand: ${props.condition} | Preisabfrage: ${props.requestDate}`}
        secondaryTypographyProps={{
          variant: 'caption',
          display: 'block',
        }}
      />
      <Typography variant={'subtitle2'} className={classes.price}>
        {props.price}
      </Typography>
    </ListItem>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    item: {
      backgroundColor: theme.palette.background.default,
    },
    image: {
      height: 40,
      width: 'auto',
    },
    textPrimary: {
      fontSize: 13,
      maxHeight: 38,
      display: 'block',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    price: {
      paddingLeft: theme.spacing(1),
      whiteSpace: 'nowrap',
    },
  })
);

export default ProductListItem;
