import { createReducer } from 'typesafe-actions';
import actions from './actions';
import produce from 'immer';
import { RootAction } from '../../createStore';
import { defaultSettingsState } from './models';

export interface SettingsState {
  excludedVendors: string[];
  maxNumberOfParcels?: number;
  customShippingFee: number;
}

export const settingsWhitelist: (keyof SettingsState)[] = [
  'excludedVendors',
  'maxNumberOfParcels',
  'customShippingFee',
];

const reducer = createReducer<SettingsState, RootAction>(defaultSettingsState)
  .handleAction(actions.setNumberOfParcels, (state, action) =>
    produce(state, draft => {
      draft.maxNumberOfParcels = action.payload;
    })
  )
  .handleAction(actions.setShippingCost, (state, action) =>
    produce(state, draft => {
      draft.customShippingFee = action.payload;
    })
  )
  .handleAction(actions.toggleVendor, (state, action) =>
    produce(state, draft => {
      const isSelected = action.meta;
      const vendorName = action.payload;
      if (isSelected) {
        draft.excludedVendors = draft.excludedVendors.filter(v => v !== vendorName);
      } else {
        draft.excludedVendors.push(vendorName);
      }
    })
  )
  .handleAction(actions.overrideExcludedVendors, (state, action) =>
    produce(state, draft => {
      draft.excludedVendors = action.payload;
    })
  );

export default reducer;
