export interface IBulkCheckout {
  vendorDisplayName: string;
  eans: string;
  checkoutUrl: string;
}

export interface ISingleCheckoutProduct {
  imageUrl: string | undefined;
  name: string;
  ean: string;
  checkoutUrl: string;
}

export interface ISingleCheckout {
  vendorDisplayName: string;
  products: ISingleCheckoutProduct[];
}

export function isBulkCheckout(checkout: IBulkCheckout | ISingleCheckout): checkout is IBulkCheckout {
  return (checkout as IBulkCheckout).eans !== undefined;
}
