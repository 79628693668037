import { IVendor } from '../../common/models';
import { SettingsState } from './reducer';

export interface ISelectedVendorVM {
  vendorName: string;
  title: string;
  isChecked: boolean;
}

export const defaultSettingsState: SettingsState = {
  excludedVendors: [],
  customShippingFee: 490,
};

export function createSelectedVendorVMs(
  vendors: Record<string, IVendor>,
  excludedVendors: string[]
): ISelectedVendorVM[] {
  return Object.values(vendors).map((vendor: IVendor) => ({
    vendorName: vendor.name,
    title: vendor.displayName,
    isChecked: !excludedVendors.includes(vendor.name),
  }));
}
