import * as React from 'react';
import CurrencyInput from 'react-currency-input';

interface IProps {
  inputRef: (ref: HTMLInputElement | null) => void;
  onChange: (event: { target: { value: string } }) => void;
}

export const MyCurrencyInput = (props: IProps) => {
  const { inputRef, onChange, ...other } = props;
  return (
    <CurrencyInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      suffix={' €'}
      decimalSeparator={','}
      onChangeEvent={(e, maskedV, floatV) =>
        onChange({
          target: {
            value: `${floatV}`,
          },
        })
      }
    />
  );
};
