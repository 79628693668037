import { createReducer } from 'typesafe-actions';
import produce from 'immer';
import actions from './actions';
import { areShoppingCartEntriesEqual, IShoppingCartEntry, IVendor } from './models';
import { RootAction } from '../createStore';

export interface CommonState {
  isLoading: boolean;
  vendors: Record<string, IVendor>;
  shoppingCartEntries: IShoppingCartEntry[];
  isFirstLaunch: boolean;
  snackbarContent?: string;
}

const defaultState: CommonState = {
  isLoading: false,
  vendors: {},
  shoppingCartEntries: [],
  isFirstLaunch: true,
};

export const commonWhiteList: (keyof CommonState)[] = ['shoppingCartEntries', 'isFirstLaunch'];

const reducer = createReducer<CommonState, RootAction>(defaultState)
  .handleAction(actions.disableFirstLaunch, (state, action) =>
    produce(state, draft => {
      draft.isFirstLaunch = false;
    })
  )
  .handleAction(actions.fetchVendors.request, (state, action) =>
    produce(state, draft => {
      draft.isLoading = true;
    })
  )
  .handleAction(actions.fetchVendors.success, (state, action) =>
    produce(state, draft => {
      draft.isLoading = false;
      draft.vendors = action.payload;
    })
  )
  .handleAction(actions.addShoppingCartEntry, (state, action) =>
    produce(state, draft => {
      draft.shoppingCartEntries.push(action.payload);
    })
  )
  .handleAction(actions.removeShoppingCartEntries, (state, action) =>
    produce(state, draft => {
      draft.shoppingCartEntries = draft.shoppingCartEntries.without(action.payload, areShoppingCartEntriesEqual);
    })
  )
  .handleAction(actions.snackbar.show, (state, action) =>
    produce(state, draft => {
      draft.snackbarContent = action.payload;
    })
  )
  .handleAction(actions.snackbar.dismiss, (state, action) =>
    produce(state, draft => {
      draft.snackbarContent = undefined;
    })
  )
  .handleAction(actions.replaceShoppingCartEntries, (state, action) =>
    produce(state, draft => {
      draft.shoppingCartEntries = action.payload;
    })
  );

export default reducer;
