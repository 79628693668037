import { Card, createStyles, makeStyles, Theme } from '@material-ui/core';
import * as React from 'react';

const useStyles = makeStyles(({ spacing }: Theme) =>
  createStyles({
    card: {
      margin: spacing(2),
    },
  })
);

interface IProps {
  children: React.ReactNode;
  additionalClass?: string;
}

export const BaseCard = ({ children, additionalClass }: IProps) => {
  const classes = useStyles();
  return <Card className={[classes.card, additionalClass].join(' ')}>{children}</Card>;
};
