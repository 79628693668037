import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';
import * as React from 'react';
import { ReactNode } from 'react';

const styles = (theme: Theme) =>
  createStyles({
    page: {
      minHeight: '100%',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: theme.palette.background.default,
    },
  });

interface IProps {
  children: ReactNode;
}

type IAllProps = IProps & WithStyles<typeof styles>;

export const Page = withStyles(styles)(({ classes, children }: IAllProps) => {
  return <div className={classes.page}>{children}</div>;
});
