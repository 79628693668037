import { useDispatch, useSelector } from 'react-redux';
import actions, { FieldName } from './actions';
import { FeedbackState } from './reducer';
import selectors from './selectors';

export function useSetField() {
  const dispatch = useDispatch();
  return <T extends FieldName>(fieldName: T, value: FeedbackState[T]) => dispatch(actions.setField(value, fieldName));
}
export function useResetError() {
  const dispatch = useDispatch();
  return () => dispatch(actions.resetError());
}
export function useSendFeedback() {
  const dispatch = useDispatch();
  return () => dispatch(actions.sendFeedback.request());
}

export const useFeedback = () => useSelector(selectors.selectFeedback);
export const useRating = () => useSelector(selectors.selectRating);
export const useEmail = () => useSelector(selectors.selectEmail);
export const useIsLoading = () => useSelector(selectors.selectIsLoading);
export const useIsSent = () => useSelector(selectors.selectIsSent);
export const useError = () => useSelector(selectors.selectError);
