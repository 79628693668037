import { RouteComponentProps } from 'react-router-dom';
import { History, LocationState } from 'history';

export function backOrReplace(props: RouteComponentProps, replaceRoute: string = '/') {
  !props.location.state ? props.history.replace(replaceRoute) : props.history.goBack();
}

export function push(props: RouteComponentProps, route: History.Path, state: LocationState = {}) {
  props.history.push(route, state);
}
