import * as React from 'react';
import { AppBar, List, ListItem, ListItemText, Select, TextField, Toolbar, Typography } from '@material-ui/core';
import { Page } from '../common/components/Page';
import ChevronIcon from '@material-ui/icons/ChevronRight';
import VendorSelectionDialog from './VendorSelectionDialog';
import { MyCurrencyInput } from './MyCurrencyInput';
import BackButton from '../common/components/BackButton';
import { backOrReplace } from '../common/utils/routerUtil';
import { RouteComponentProps } from 'react-router-dom';
import { settingsHooks } from '@luso/common/src/pages/settings';
import { commonHooks } from '@luso/common/src/common';
import { formatMoney } from '@luso/common/src/utils/formatter';
import { range } from '@luso/common/src/utils/helper';

type IOwnProps = RouteComponentProps;

const SettingsPage = (props: IOwnProps) => {
  const [isDialogOpen, setDialogOpen] = React.useState(false);
  const excludedVendors = settingsHooks.useExcludedVendors();
  const activeVendorCount = settingsHooks.useActiveVendorCount();
  const totalVendorCount = commonHooks.useNumberOfVendors();
  const maxParcelCount = settingsHooks.useMaxParcelCount();
  const shippingCost = settingsHooks.useShippingCost();

  const setParcelCount = settingsHooks.useSetParcelCount();
  const setShippingCost = settingsHooks.useSetShippingCost();

  const [value, setValue] = React.useState(shippingCost);

  return (
    <Page>
      <AppBar position="relative">
        <Toolbar>
          <BackButton handleBackClicked={() => backOrReplace(props, '/partition')} />
          <Typography variant="h6" color="inherit">
            Einstellungen
          </Typography>
        </Toolbar>
      </AppBar>
      <List>
        <ListItem divider={true} button onClick={() => setDialogOpen(true)}>
          <ListItemText
            primary={'Ankäufer'}
            secondary={excludedVendors.length === 0 ? 'Alle' : `${activeVendorCount} von ${totalVendorCount}`}
          />
          <ChevronIcon color={'action'} />
        </ListItem>

        <ListItem divider={true}>
          <ListItemText
            primary={'Maximale Paketanzahl'}
            secondary={maxParcelCount === totalVendorCount ? 'Unbegrenzt' : `Nicht mehr als ${maxParcelCount} Pakete`}
          />
          <Select native value={maxParcelCount} onChange={e => setParcelCount(parseInt(e.target.value as string))}>
            {range(1, totalVendorCount + 1).map(v => (
              <option key={v} value={v}>
                {v === totalVendorCount ? 'Unbegrenzt' : v}
              </option>
            ))}
          </Select>
        </ListItem>
      </List>

      <ListItem divider={true}>
        <ListItemText primary={'Versandkosten'} secondary={formatMoney(shippingCost) + ' bei Selbstzahlung'} />
        <TextField
          style={{
            width: 60,
            textAlign: 'right',
          }}
          value={value / 100}
          onChange={e => setValue(Math.round(parseFloat(e.target.value) * 100))}
          onBlur={() => {
            setShippingCost(value);
          }}
          InputProps={{
            inputComponent: MyCurrencyInput as any,
          }}
        />
      </ListItem>

      <VendorSelectionDialog visible={isDialogOpen} onDismiss={() => setDialogOpen(false)} />
    </Page>
  );
};

export default SettingsPage;
