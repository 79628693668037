export const objectFromEntries = function <K extends string, V>(entries: Array<[K, V]>): Record<K, V> {
  return Object.assign({}, ...entries.map(([k, v]) => ({ [k]: v })));
};

export const jsonEquals = (a: any, b: any): boolean => {
  return JSON.stringify(a) === JSON.stringify(b);
};

export type KeyablePropertyKeys<T> = { [P in keyof T]: T[P] extends keyof any ? P : never }[keyof T];

export const normalize = <V, K extends KeyablePropertyKeys<V>>(values: V[], keyChooser: K): Record<K, V> => {
  return values.reduce((obj: Record<keyof any, V>, curr) => {
    const key: any = curr[keyChooser];
    obj[key] = curr;
    return obj;
  }, {} as Record<keyof any, V>);
};
