import {
  formatMoney,
  getProductConditionCode,
  getProductConditionDisplayString,
  relativeDate,
} from '../../utils/formatter';
import {
  calculateShippingCost,
  IPartitionProduct,
  IShoppingCartEntry,
  IVendor,
  sumProductPrices,
} from '../../common/models';
import { createAutoTransferUrl } from '../../utils/urlUtils';

export interface ISellableProductsHeader {
  vendor: string;
  vendorDisplay: string;
  price: string;
  shippingCost: string;
  autoTransferUrl?: string;
}

export interface IUnsellableProductsHeader {
  title: string;
  info: string;
}

export interface IProductListItem {
  title: string;
  condition: string;
  imageUrl: string;
  price: string;
  ean: string;
  isSelected: boolean;
  indexedEan: string;
  requestDate: string;
  showsCheckBox: boolean;
}

export interface SearchParams {
  shoppingCartEntries: IShoppingCartEntry[];
  vendorNames: string[];
  shippingCost: number;
  maxNumberOfParcels: number;
}

export type ListItemType = IProductListItem | IUnsellableProductsHeader | ISellableProductsHeader;

export const isSellableProductsHeader = (item: ListItemType): item is ISellableProductsHeader =>
  !!(item as ISellableProductsHeader).vendor;
export const isUnsellableProductsHeader = (item: ListItemType): item is IUnsellableProductsHeader =>
  !!(item as IUnsellableProductsHeader).info;
export const isProductListItem = (item: ListItemType): item is IProductListItem =>
  !!(item as IProductListItem).condition;

export interface IListSection {
  header: ISellableProductsHeader | IUnsellableProductsHeader;
  data: IProductListItem[];
}

function createListItems(products: IPartitionProduct[], selectedProducts?: string[]): IProductListItem[] {
  return products.map(p => ({
    title: p.name,
    condition: getProductConditionDisplayString(p.condition),
    price: formatMoney(p.price),
    imageUrl: p.imageUrl || '',
    ean: p.ean,
    requestDate: relativeDate(p.requestDate),
    isSelected: selectedProducts?.includes(p.indexedEan) ?? false,
    indexedEan: p.indexedEan,
    showsCheckBox: selectedProducts !== undefined,
  }));
}

export function createSection(
  vendor: IVendor,
  products: IPartitionProduct[],
  selfShippingCost: number,
  selectedProducts?: string[]
): IListSection {
  const totalPrice = sumProductPrices(products);
  const shippingCost = calculateShippingCost(vendor, totalPrice, selfShippingCost);
  return {
    header: {
      vendor: vendor.name,
      vendorDisplay: vendor.displayName,
      price: formatMoney(totalPrice),
      shippingCost:
        shippingCost === 0 ? '(kostenloser Versand)' : `(abzgl. ${formatMoney(shippingCost)} Versandkosten)`,
      autoTransferUrl:
        vendor.autoTransferUrl && vendor.eanSeparator
          ? createAutoTransferUrl(
              vendor.autoTransferUrl,
              products.map(p => p.ean),
              products.map(p => getProductConditionCode(p.condition)),
              vendor.eanSeparator
            )
          : undefined,
    },
    data: createListItems(products, selectedProducts),
  };
}

export function createUnsellableProductSection(unsellableProducts: IPartitionProduct[], selectedProducts?: string[]) {
  return {
    header: {
      title: 'Unverkäufliche Produkte',
      info:
        'Diese Produkte können nicht verkauft werden, da du bei keinem der Ankäufer der Artikel den Mindestbestellwert erreichst. Lege weitere Artikel in deine Verkaufsbox, um die Ankaufgrenze zu überschreiten.',
    },
    data: createListItems(unsellableProducts, selectedProducts),
  };
}
