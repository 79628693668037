import { Api } from './api/api';
import { ShareFunction } from '../pages/partition/ShareService';
import { getContext } from 'redux-saga/effects';
import { MomoxAdapter } from './momox/momoxAdapter';
import { ErrorLogger } from './errorLogger/errorLogger';

export interface ILocalDependencies {
  storage: any;
  platform: string;
  share?: ShareFunction;
}

export interface ICommonDependencies {
  api: Api;
  momoxAdapter: MomoxAdapter,
  errorLogger: ErrorLogger
}

export type IDependencies = ILocalDependencies & ICommonDependencies;

export function* getService<S extends keyof IDependencies>(serviceName: S): IDependencies[S] {
  return yield getContext(serviceName);
}
