import React from 'react';
import {
  Avatar,
  Button,
  ButtonBase,
  Card,
  CardActions,
  CardContent,
  Collapse,
  createStyles,
  Divider,
  Grid,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';
import { PaperProps } from '@material-ui/core/Paper';
import { CardProps } from '@material-ui/core/Card';
import { Hidden } from './Hidden';

interface IProps {
  open: boolean;
  label: string;
  className?: string;
  buttonLabel?: string;
  buttonOnClick?: () => void;
  buttonProps?: any;
  buttonComponent?: any;
  showDismissButton?: boolean;
  dismissButtonLabel?: string;
  onClose?: () => void;
  icon?: React.ReactNode;
  iconProps?: any;
  appBar?: boolean;
  paperProps?: PaperProps;
  cardProps?: CardProps;
}

const Banner = (props: IProps) => {
  const classes = useStyles();

  const renderButtons = () => {
    const {
      onClose,
      showDismissButton,
      dismissButtonLabel,
      buttonOnClick,
      buttonLabel,
      buttonComponent,
      buttonProps,
    } = props;

    return (
      <>
        <span className={classes.flex} />

        <Grid item className={classes.buttons}>
          {showDismissButton && (
            <Button variant="text" onClick={onClose}>
              {dismissButtonLabel}
            </Button>
          )}

          {!!buttonLabel && (
            <Button variant="text" onClick={buttonOnClick} component={buttonComponent} {...buttonProps}>
              {buttonLabel}
            </Button>
          )}
        </Grid>
      </>
    );
  };

  const {
    open,
    label,
    icon,
    iconProps,
    appBar,
    showDismissButton,
    buttonLabel,
    paperProps,
    cardProps,
    className,
  } = props;

  const hasButton = Boolean(showDismissButton || buttonLabel);

  return (
    <Collapse in={open} className={className}>
      <Paper elevation={0} className={classes.root} {...paperProps}>
        <Card elevation={0} className={appBar ? classes.appBar : ''} {...cardProps}>
          <CardContent className={classes.cardContent}>
            <Grid
              container
              wrap="nowrap"
              spacing={2}
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              {icon && (
                <Grid item>
                  <Avatar className={classes.avatar} {...iconProps}>
                    {icon}
                  </Avatar>
                </Grid>
              )}

              <Grid item className={classes.label}>
                <Typography variant="subtitle2">{label}</Typography>
              </Grid>

              <Hidden smDown>{appBar && hasButton && renderButtons()}</Hidden>
            </Grid>
          </CardContent>

          {!appBar && hasButton && (
            <Hidden smDown>
              <CardActions>{renderButtons()}</CardActions>
            </Hidden>
          )}

          {hasButton && (
            <Hidden mdUp>
              <CardActions>{renderButtons()}</CardActions>
            </Hidden>
          )}

          <Hidden smDown>
            <div />
          </Hidden>
        </Card>

        <Divider />
      </Paper>
    </Collapse>
  );
};

Banner.defaultProps = {
  open: true,
  buttonOnClick: () => {},
  showDismissButton: true,
  dismissButtonLabel: 'Dismiss',
  appBar: false,
  buttonComponent: ButtonBase,
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'block',
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    appBar: {
      [theme.breakpoints.up('md')]: {
        width: 1100,
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: '100%',
      },
    },
    cardContent: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    avatar: {
      backgroundColor: theme.palette.secondary.main,
    },
    flex: {
      flexGrow: 1,
    },
    buttons: {
      alignSelf: 'flex-end',
      paddingLeft: '90px !important',
    },
    label: {
      alignSelf: 'center',
    },
  })
);

export default Banner;
