export class ApiError extends Error {
  public name: string;
  public description: string;

  constructor(name: string, description: string) {
    super(description);

    Object.setPrototypeOf(this, ApiError.prototype);

    this.name = name;
    this.description = description;
  }
}

export class NetworkError extends ApiError {
  constructor() {
    super('NetworkError', 'Es konnte keine Verbindung zum Server hergestellt werden.');
  }
}
