import { useDispatch, useSelector } from 'react-redux';
import actions from './actions';
import selectors from './selectors';

export function useToggleAll() {
  const dispatch = useDispatch();
  return () => {
    dispatch(actions.toggleAll());
  };
}

export function useDeleteSelectedProducts() {
  const dispatch = useDispatch();
  return () => {
    dispatch(actions.deleteSelectedProducts());
  };
}

export function useFetchPartition() {
  const dispatch = useDispatch();
  return () => {
    dispatch(actions.fetchPartition.request());
  };
}

export function useEnterActionMode() {
  const dispatch = useDispatch();
  return () => {
    dispatch(actions.enterActionMode());
  };
}

export function useCancelActionMode() {
  const dispatch = useDispatch();
  return () => {
    dispatch(actions.cancelActionMode());
  };
}

export function useToggleItem() {
  const dispatch = useDispatch();
  return (indexedEan: string) => {
    dispatch(actions.toggleItem(indexedEan));
  };
}

export function useHideFeedbackBanner() {
  const dispatch = useDispatch();
  return (days: number | null) => {
    dispatch(actions.hideFeedbackBannerForDays(days));
  };
}

export function useSharePartition() {
  const dispatch = useDispatch();
  return () => {
    dispatch(actions.savePartition.request());
  };
}

export function useLoadSharedPartition() {
  const dispatch = useDispatch();
  return (partitionId: string) => {
    dispatch(actions.loadPartition.request(partitionId));
  };
}

export function useSections() {
  return useSelector(selectors.selectSections);
}

export function useTotalPrice() {
  return useSelector(selectors.selectTotalPrice);
}

export function useIsLoading() {
  return useSelector(selectors.selectIsLoading);
}

export function useError() {
  return useSelector(selectors.selectError);
}

export function useIsInActionMode() {
  return useSelector(selectors.selectIsInActionMode);
}

export function useShowFeedbackBanner() {
  return useSelector(selectors.selectShowFeedbackBanner);
}

export function useIsPartitionEmpty() {
  return useSelector(selectors.selectIsPartitionEmpty);
}

export function useAreAllSelected() {
  return useSelector(selectors.selectAreAllSelected);
}

export function useNumberOfSelections() {
  return useSelector(selectors.selectNumberOfSelections);
}

export function useShouldFetchPartition() {
  return useSelector(selectors.shouldFetchPartition);
}
