import { getType } from 'typesafe-actions';
import { all, put, takeLatest } from 'redux-saga/effects';
import actions from './actions';
import { IVendor, IVendorProduct } from '../../common/models';
import { getService } from '../../services';
import { ApiError } from '../../services/api/apiError';
import { takeVendors } from '../../common/sagas';
import { Api } from '../../services/api/api';
import { MomoxAdapter } from '../../services/momox/momoxAdapter';

const fetchProductsSaga = takeLatest(getType(actions.fetchProducts.request), function* (
  action: ReturnType<typeof actions.fetchProducts.request>
) {
  const [vendors, error]: [Record<string, IVendor> | null, ApiError | null] = yield takeVendors();
  if (vendors) {
    yield all(Object.values(vendors).map(v => fetchSingleProduct(v.name, action.payload)));
    yield put(actions.fetchProducts.completed());
  }
});

function* fetchSingleProduct(vendorName: string, ean: string) {
  const api: Api = yield getService('api');
  const momoxAdapter: MomoxAdapter = yield getService('momoxAdapter');

  try {
    const product: IVendorProduct =
      vendorName === 'momox' ? yield momoxAdapter.fetchProduct(ean) : yield api.fetchProduct(vendorName, ean);
    yield put(
      actions.fetchProducts.productReceived({
        vendor: vendorName,
        result: product,
      })
    );
  } catch (e) {
    yield put(
      actions.fetchProducts.productReceived({
        vendor: vendorName,
        result: e as ApiError,
      })
    );
  }
}

export default [fetchProductsSaga];
