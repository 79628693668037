import * as React from 'react';
import {
  CardContent,
  CardHeader,
  createStyles,
  Divider,
  FormControl,
  FormControlLabel,
  makeStyles,
  Radio,
  RadioGroup,
  Theme,
} from '@material-ui/core';
import { ProductCondition, productConditionToStringMap } from '@luso/common/src/common/models';
import { BaseCard } from '../../common/components/BaseCard';
import { searchHooks } from '@luso/common/src/pages/search';

interface IOwnProps {
  cardClass?: string;
}

const ConditionCard = (props: IOwnProps) => {
  const classes = useStyles();

  const chooseCondition = searchHooks.useChooseCondition();
  const selectedCondition = searchHooks.useCondition();
  const selectCondition = (condition: string) => chooseCondition(condition as ProductCondition);

  return (
    <BaseCard additionalClass={props.cardClass}>
      <CardHeader title={'Bewerte den Artikelzustand'} titleTypographyProps={{ variant: 'h6', color: 'textPrimary' }} />
      <Divider />
      <CardContent>
        <FormControl component="fieldset" className={classes.formControl}>
          <RadioGroup
            aria-label="Produktzustand"
            name="zustand"
            value={selectedCondition}
            onChange={(e, val) => selectCondition(val as ProductCondition)}
          >
            {Array.from(productConditionToStringMap.entries()).map(([condition, displayStr]) => (
              <FormControlLabel key={displayStr} value={condition} control={<Radio />} label={displayStr} />
            ))}
          </RadioGroup>
        </FormControl>
      </CardContent>
    </BaseCard>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      padding: theme.spacing(0, 2),
    },
  })
);

export default ConditionCard;
