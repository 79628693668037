import * as React from 'react';
import { createStyles, Fab, makeStyles, Theme } from '@material-ui/core';
import { AddToBoxIcon } from '../../common/components/Icons';

interface IProps {
  isLoading: boolean;
  handleAddToCartClicked: () => void;
  isFabSubmittable: boolean;
}

const AddToCartButton = (props: IProps) => {
  const classes = useStyles();

  return (
    <Fab
      disabled={!props.isFabSubmittable}
      className={classes.fab}
      color="secondary"
      onClick={props.handleAddToCartClicked}
    >
      <AddToBoxIcon />
    </Fab>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      position: 'absolute',
      bottom: -25,
      right: 30,

      '&:disabled': {
        backgroundColor: '#ccc',
        color: '#888',
      },
    },
  }),
);

export default AddToCartButton;
