import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import { ProductCondition } from '../../common/models';
import selectors from './selectors';
import actions from './actions';

export function useFetchProducts(ean?: string) {
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (ean !== undefined) {
      dispatch(actions.fetchProducts.request(ean));
    }
  }, [ean]);
}

export function useConfirmPriceInfoBanner() {
  const dispatch = useDispatch();
  return () => dispatch(actions.confirmPriceInfoBanner());
}

export function useChooseCondition() {
  const dispatch = useDispatch();
  return (condition: ProductCondition) => dispatch(actions.selectProductCondition(condition));
}

export const useIsLoading = (): boolean => useSelector(selectors.selectIsLoading);
export const useTitle = (): string | undefined => useSelector(selectors.selectTitle);
export const useEan = (): string | undefined => useSelector(selectors.selectEan);
export const useCondition = (): ProductCondition => useSelector(selectors.selectCondition);
export const useImageUrl = (): string | undefined => useSelector(selectors.selectImageUrl);
export const useShowPriceInfoBanner = (): boolean => useSelector(selectors.selectShowPriceInfoBanner);
export const useErrorMessage = (): string | undefined => useSelector(selectors.selectErrorMessage);
export const usePriceRange = () => useSelector(selectors.selectPriceRange);
export const useOffers = () => useSelector(selectors.selectOffersSortedByPrice);
