import { createStyles, makeStyles, Theme } from '@material-ui/core';
import * as React from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import PartitionListItem from './PartitionListItem';
import { IListSection, ListItemType } from '@luso/common/src/pages/partition/models';

interface IProps {
  sections: IListSection[];
  isInEditMode: boolean;
  onToggle: (indexedEan: string) => void;
}

const PartitionList = (props: IProps) => {
  const classes = useStyles();

  const listItems = flattenSection(props.sections);
  const itemCount = listItems.length;

  return (
    <div className={classes.container}>
      <AutoSizer>
        {({ height, width }) => (
          <FixedSizeList
            className={props.isInEditMode ? classes.list : undefined}
            itemSize={88}
            height={height}
            itemCount={itemCount}
            width={width}
            itemData={listItems}
          >
            {PartitionListItem}
          </FixedSizeList>
        )}
      </AutoSizer>
    </div>
  );
};

function flattenSection(sections: IListSection[]): Array<ListItemType> {
  const headerAndItems = Array.from(sections.map(s => [s.header, ...s.data]));
  return ([] as Array<ListItemType>).concat(...headerAndItems);
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      flexGrow: 1,
    },
    list: {
      '& > div': {
        paddingBottom: 55,
      },
    },
  })
);

export default PartitionList;
