import * as React from 'react';
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { responsiveCss, responsiveFontSize } from '../../common/utils/jssUtils';
import ImageWithFallback from '../../common/components/ImageWithFallback';

interface IProps {
  ean: string;
  title: string;
  imageUrl?: string | null;
}

const ContentHeader = (props: IProps) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.imgContainer}>
        <ImageWithFallback url={props.imageUrl} className={classes.image} />
      </div>
      <div className={classes.textContainer}>
        <Typography className={classes.title} variant="h1" color="inherit">
          {props.title}
        </Typography>
        <Typography className={classes.ean} variant="body1" color="inherit">
          {props.ean}
        </Typography>
      </div>
    </div>
  );
};

const useStyles = makeStyles(({ breakpoints, spacing }: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'row',
      alignContent: 'center',
    },
    image: {
      height: '100%',
    },
    textContainer: {},
    imgContainer: {
      height: '25vw',
      maxHeight: 200,
      paddingRight: spacing(3),
    },
    title: {
      ...responsiveCss(breakpoints, 'fontSize', '1rem', '1.75rem', '2.5rem'),
      lineHeight: 1.3,
      fontWeight: 700,
      paddingBottom: spacing(1),
    },
    ean: {
      ...responsiveFontSize(breakpoints, '0.75rem', '1rem', '1.5rem'),
      fontStyle: 'italic',
    },
  })
);

export default ContentHeader;
