import { createMigrate as createReduxMigrate } from 'redux-persist';
import { MigrationManifest, Storage, WebStorage } from 'redux-persist/es/types';

interface MigrationConfig {
  debug: boolean;
}

async function migrateLegacyState(storage: WebStorage) {
  const storedValue = await storage.getItem('@rootStoreKey');
  await storage.removeItem('@rootStoreKey');
  if (!storedValue) return undefined;

  const legacyState = JSON.parse(storedValue);
  return {
    common: {
      isFirstLaunch: false,
      shoppingCartEntries: legacyState.shoppingCartEntries,
    },
    settings: {
      excludedVendors: legacyState.settingsStore?.excludedVendors ?? [],
      maxNumberOfParcels: legacyState.settingsStore?.maxNumberOfParcels || undefined,
      customShippingFee: legacyState.settingsStore?.customShippingFee ?? 490,
    },
    partition: {
      showFeedbackDate: legacyState.showFeedbackDate,
    },
  };
}

/**
 * Legacy migration wrapper
 */
export function createMigrate(storage: Storage, migrations: MigrationManifest, config?: MigrationConfig) {
  return async (state: any, currentVersion: number) => {
    const reduxMigrate = createReduxMigrate(migrations, config);

    // If state from current storage is empty try migrate state from legacy storage
    // This also triggers versioned migrations
    if (!state) {
      try {
        console.log('redux-persist/legacy: no inbound state, running legacy state migration');

        state = await migrateLegacyState(storage);
      } catch (migrateLegacyStateError) {
        console.error('redux-persist/legacy: migration error', migrateLegacyStateError);
      }
    }
    return reduxMigrate(state, currentVersion);
  };
}

/**
 *  Versioned migrations
 */
export default {};
