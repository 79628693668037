import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import * as React from 'react';
import { createRef } from 'react';
import { BrowserBarcodeReader, Exception, Result } from '@zxing/library';

interface Props {
  onBarcode?: (barcode: string) => void;
}

const BarcodeScanner = ({ onBarcode }: Props) => {
  const classes = useStyles();
  const [status, setStatus] = React.useState<string | undefined>('Lade Barcodescanner...');
  const videoRef = createRef<HTMLVideoElement>();
  const codeReader = React.useRef(new BrowserBarcodeReader());

  const handleScanCallback = React.useCallback(
    (result: Result, error?: Exception) => {
      if (error) {
        //console.error("Scan Error", error);
      } else if (result && onBarcode) {
        onBarcode(result.getText());
      }
    },
    [onBarcode]
  );

  React.useEffect(() => {
    const currentCodeReader = codeReader.current;
    currentCodeReader
      .decodeFromVideoDevice(null, videoRef.current, handleScanCallback)
      .then(() => setStatus(undefined))
      .catch(e => setStatus(e.toString()));
    return () => {
      currentCodeReader.stopContinuousDecode();
    };
  }, [handleScanCallback, videoRef]);

  return (
    <React.Fragment>
      {!!status && (
        <div className={classes.container}>
          <Typography variant="body1">{status}</Typography>
        </div>
      )}
      <video className={`${classes.video} ${!!status && classes.hide}`} id="video" ref={videoRef} />
    </React.Fragment>
  );
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    video: {
      width: '100%',
      flexGrow: 1,
      objectFit: 'cover',
    },
    container: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    hide: {
      display: 'none',
    },
  })
);

export default BarcodeScanner;
