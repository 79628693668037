import { Box, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import * as React from 'react';
import { CSSProperties } from 'react';
import DialogButton from '../../common/components/DialogButton';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { IUnsellableProductsHeader } from '@luso/common/src/pages/partition/models';

const UnsellableProductsHeader = (props: IUnsellableProductsHeader & { style: CSSProperties }) => {
  const classes = useStyles();

  return (
    <Box boxShadow={2} style={props.style} className={classes.box}>
      <div className={classes.container}>
        <div>
          <Typography variant={'h6'} className={classes.title}>
            Unverkäufliche Produkte
          </Typography>
        </div>
        <DialogButton
          icon={<HelpOutlineIcon />}
          dialogTitle={props.title}
          dialogContent={<Typography>{props.info}</Typography>}
        />
      </div>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    box: {
      backgroundColor: theme.palette.common.white,
      zIndex: 1,
    },
    container: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: '100%',
      padding: theme.spacing(0, 2),
    },
    title: {},
  })
);

export default UnsellableProductsHeader;
