import { createReducer } from 'typesafe-actions';

import actions from './actions';
import produce from 'immer';
import { RootAction } from '../../createStore';
import { ApiError } from '../../services/api/apiError';

export interface FeedbackState {
  rating?: number;
  feedback?: string;
  isLoading: boolean;
  isSent: boolean;
  error?: ApiError;
  email?: string;
}

const defaultState: FeedbackState = {
  isLoading: false,
  isSent: false,
};

const reducer = createReducer<FeedbackState, RootAction>(defaultState)
  .handleAction(actions.setField, (state, action) =>
    produce(state, draft => {
      draft[action.meta] = action.payload as any;
    })
  )
  .handleAction(actions.resetError, (state, action) =>
    produce(state, draft => {
      draft.error = undefined;
    })
  )
  .handleAction(actions.sendFeedback.request, (state, action) =>
    produce(state, draft => {
      draft.error = undefined;
      draft.isLoading = true;
      draft.isSent = false;
    })
  )
  .handleAction(actions.sendFeedback.success, (state, action) =>
    produce(state, draft => {
      draft.isLoading = false;
      draft.isSent = true;
    })
  )
  .handleAction(actions.sendFeedback.failure, (state, action) =>
    produce(state, draft => {
      draft.isLoading = false;
      draft.error = action.payload;
    })
  );

export default reducer;
