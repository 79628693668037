import * as React from 'react';
import ContentLoader from 'react-content-loader';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

interface IProps {}

const LoadingHeader = (props: IProps) => {
  const classes = useStyles();

  return (
    <ContentLoader
      className={classes.loader}
      height={120}
      width={346}
      speed={2}
      foregroundColor="#00000029"
      backgroundColor="#A6A6A629"
    >
      <rect x="96" y="40" rx="4" ry="4" width="250" height="8" />
      <rect x="96" y="60" rx="4" ry="4" width="250" height="8" />
      <rect x="96" y="80" rx="3" ry="3" width="180" height="6" />
      <rect x="0" y="0" width="80" height="120" />
    </ContentLoader>
  );
};

const useStyles = makeStyles(({ spacing }: Theme) =>
  createStyles({
    loader: {
      height: '25vw',
      maxHeight: 200,
    },
  })
);

export default LoadingHeader;
