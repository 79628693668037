import { createAction, createAsyncAction } from 'typesafe-actions';
import { ApiError } from '../../services/api/apiError';
import { IPartition } from '../../common/models';
import { SearchParams } from './models';

const fetchPartition = createAsyncAction(
  '@partition/FETCH_PARTITION_REQUEST',
  '@partition/FETCH_PARTITION_SUCCESS',
  '@partition/FETCH_PARTITION_FAILURE'
)<void, [IPartition, SearchParams], ApiError>();

const savePartition = createAsyncAction(
  '@partition/SAVE_PARTITION_REQUEST',
  '@partition/SAVE_PARTITION_SUCCESS',
  '@partition/SAVE_PARTITION_FAILURE'
)<void, void, ApiError>();

const loadPartition = createAsyncAction(
  '@partition/LOAD_PARTITION_REQUEST',
  '@partition/LOAD_PARTITION_SUCCESS',
  '@partition/LOAD_PARTITION_FAILURE'
)<string, void, ApiError>();

const enterActionMode = createAction('@partition/ENTER_ACTION_MODE')();
const cancelActionMode = createAction('@partition/CANCEL_ACTION_MODE')();
const toggleItem = createAction('@partition/TOGGLE_ITEM')<string>();
const hideFeedbackBannerForDays = createAction('@partition/HIDE_FEEDBACK_BANNER')<number | null>();
const toggleAll = createAction('@partition/TOGGLE_ALL')();
const deleteSelectedProducts = createAction('@partition/DELETE_SELECTED_PRODUCTS')();

export default {
  fetchPartition,
  savePartition,
  loadPartition,
  enterActionMode,
  toggleItem,
  cancelActionMode,
  hideFeedbackBannerForDays,
  toggleAll,
  deleteSelectedProducts,
};
