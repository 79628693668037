import * as React from 'react';
import placeholder from './placeholder.png';

interface Props {
  url?: string | null;
  className?: string;
}

const ImageWithFallback = (props: Props) => {
  const url = !!props.url ? props.url : placeholder;
  return <img alt={''} src={url} className={props.className} />;
};

export default ImageWithFallback;
