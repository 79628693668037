import { RootState } from '../../createStore';
import { createSelector } from 'reselect';
import { commonSelectors } from '../../common';
import { createSection, createUnsellableProductSection, SearchParams } from './models';
import { calculateTotalPrice, getAllProductsInPartition, IPartitionProduct } from '../../common/models';
import { formatMoney } from '../../utils/formatter';
import { settingsSelectors } from '../settings';
import { equals } from '../../utils/deep-equal';

const selectIsLoading = (state: RootState) => state.partition.isLoading;
const selectError = (state: RootState) => state.partition.error;
const selectPartition = (state: RootState) => state.partition.partition;
const selectIsInActionMode = (state: RootState) => state.partition.isInActionMode;
const selectSelectedIndexedEans = (state: RootState) => state.partition.selectedIndexedEans;
const selectShowFeedbackBanner = (state: RootState) =>
  !!state.partition.showFeedbackDate && state.partition.showFeedbackDate < Date.now();

const selectSections = createSelector(
  commonSelectors.selectVendors,
  selectPartition,
  selectSelectedIndexedEans,
  selectIsInActionMode,
  settingsSelectors.selectCustomShippingFee,
  (vendors, partition, selectedIndexedEans, isInActionMode, selfShippingCost) => {
    const createSections = ([vendorName, products]: [string, IPartitionProduct[]]) =>
      createSection(vendors[vendorName], products, selfShippingCost, isInActionMode ? selectedIndexedEans : undefined);

    const regularSections = partition ? Object.entries(partition.partition).map(createSections) : [];
    if (partition && partition.unsellableProducts.length) {
      return regularSections.concat(
        createUnsellableProductSection(partition.unsellableProducts, isInActionMode ? selectedIndexedEans : undefined)
      );
    }
    return regularSections;
  }
);

const selectAllProductsInPartition = createSelector(selectPartition, partition => {
  return partition ? getAllProductsInPartition(partition) : [];
});

const selectTotalPrice = createSelector(
  commonSelectors.selectVendors,
  selectPartition,
  settingsSelectors.selectCustomShippingFee,
  (vendors, partition, selfShippingCost) => {
    const totalPrice = partition ? calculateTotalPrice(partition, vendors, selfShippingCost) : 0;
    return formatMoney(totalPrice);
  }
);

const selectIsPartitionEmpty = createSelector(selectPartition, partition => {
  return partition ? Object.keys(partition).length === 0 : true;
});

const selectCountProducts = createSelector(selectAllProductsInPartition, products => {
  return products.length;
});

const selectNumberOfSelections = createSelector(selectSelectedIndexedEans, selectedIndexedEans => {
  return selectedIndexedEans.length;
});

const selectAreAllSelected = createSelector(
  selectNumberOfSelections,
  selectCountProducts,
  (numberOfSelections, numberOfProducts) => {
    return numberOfSelections === numberOfProducts;
  }
);

const selectSelectedProducts = createSelector(
  selectSelectedIndexedEans,
  selectAllProductsInPartition,
  (selectedIndexedEans, products) => {
    return products.filter(p => selectedIndexedEans.includes(p.indexedEan));
  }
);

const selectPreviousSearchParams = (state: RootState) => state.partition.searchParams;

const selectCurrentSearchParams = createSelector(
  commonSelectors.selectShoppingCartEntries,
  settingsSelectors.selectActiveVendorNames,
  settingsSelectors.selectMaxNumberOfParcels,
  settingsSelectors.selectCustomShippingFee,
  (shoppingCartEntries, vendorNames, maxNumberOfParcels, shippingCost): SearchParams => ({
    shoppingCartEntries,
    vendorNames,
    maxNumberOfParcels,
    shippingCost,
  })
);

const shouldFetchPartition = createSelector(
  selectCurrentSearchParams,
  selectPreviousSearchParams,
  (current, previous) => {
    return !equals(current, previous);
  }
);

export default {
  selectIsLoading,
  selectError,
  selectSelectedIndexedEans,
  selectSections,
  selectTotalPrice,
  selectIsInActionMode,
  selectShowFeedbackBanner,
  selectIsPartitionEmpty,
  selectNumberOfSelections,
  selectAreAllSelected,
  selectSelectedProducts,
  selectCurrentSearchParams,
  shouldFetchPartition,
  selectPartition,
};
