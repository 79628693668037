import { getType } from 'typesafe-actions';
import actions from './actions';
import { put, race, select, take, takeLeading } from 'redux-saga/effects';
import { getService } from '../services';
import { Api } from '../services/api/api';
import selectors from './selectors';
import { IVendor } from './models';
import { ApiError } from '../services/api/apiError';
import { RootState } from '../createStore';

const fetchVendorsSaga = takeLeading(getType(actions.fetchVendors.request), function* () {
  const api: Api = yield getService('api');
  try {
    const vendors: Record<string, IVendor> = yield api.fetchVendors();
    yield put(actions.fetchVendors.success(vendors));
  } catch (e) {
    yield put(actions.fetchVendors.failure(e as ApiError));
  }
});

export function* takeVendors() {
  const state: RootState = yield select();
  const vendors = selectors.selectVendors(state);

  if (Object.keys(vendors).length === 0) {
    yield put(actions.fetchVendors.request());
    const { success, failure } = yield race({
      success: take(actions.fetchVendors.success),
      failure: take(actions.fetchVendors.failure),
    });
    return [success?.payload ?? null, failure?.payload ?? null];
  }

  return [vendors, null];
}

export default [fetchVendorsSaga];
