import {
  AppBar,
  createStyles,
  makeStyles,
  Tab,
  Tabs,
  Theme,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import * as React from 'react';
import { Page } from '../common/components/Page';
import { BarcodeScannerIcon, BoxIcon, ManualInputIcon } from '../common/components/Icons';
import ScanTab from './ScanTab/ScanTab';
import ManualInputTab from './ManualInputTab/ManualInputTab';
import { RouteComponentProps } from 'react-router-dom';
import OverflowMenu from './OverflowMenu';
import { push } from '../common/utils/routerUtil';
import { IconButtonWithBadge } from '../common/components/IconButtonWithBadge';
import InstallationBanner from '../common/components/InstallationBanner';
import { commonHooks } from '@luso/common/src/common';

type IOwnProps = RouteComponentProps;

const HomePage = (props: IOwnProps) => {
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = React.useState(0);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const numberOfArticles = commonHooks.useNumberOfShoppingCartEntries();
  const [hasCamera, setHasCamera] = React.useState<boolean | undefined>(undefined);

  React.useEffect(() => {
    (window as any).hasCameraPromise.then(setHasCamera);
  }, []);

  const onBarcode = (barcode: string) => {
    push(props, `/search/${barcode}`);
  };

  const navigateToPartition = () => {
    push(props, `/partition`);
  };

  return (
    <Page>
      <AppBar position="relative">
        <Toolbar>
          <Typography className={classes.title} variant="h6" color="inherit">
            Sell4More
          </Typography>
          <div className={classes.actions}>
            <IconButtonWithBadge
              badgeContent={numberOfArticles}
              ariaLabel={'Zur Verkaufsbox'}
              onClick={navigateToPartition}
            >
              <BoxIcon />
            </IconButtonWithBadge>
            <OverflowMenu />
          </div>
        </Toolbar>

        <Tabs value={selectedTab} onChange={(e, val) => setSelectedTab(val)} variant="fullWidth" centered={!isMobile}>
          <Tab icon={<BarcodeScannerIcon />} label="Barcode-Scanner" />
          <Tab icon={<ManualInputIcon />} label="Manuelle Eingabe" />
        </Tabs>
      </AppBar>
      {selectedTab === 0 && <ScanTab hasCamera={hasCamera} onBarcode={onBarcode} />}
      {selectedTab === 1 && <ManualInputTab onBarcode={onBarcode} />}
      <InstallationBanner />
    </Page>
  );
};

const useStyles = makeStyles(({ spacing, mixins }: Theme) =>
  createStyles({
    title: {
      flexGrow: 1,
    },

    actions: {
      display: 'flex',
    },
  })
);

export default HomePage;
