import { Storage } from 'redux-persist/es/types';
import { createTransform, persistReducer } from 'redux-persist';
import { combineReducers } from 'redux';
import { commonReducer, commonWhiteList } from './common';
import { settingsReducer, settingsWhitelist } from './pages/settings';
import { searchReducer } from './pages/search';
import { partitionReducer, partitionWhitelist } from './pages/partition';
import { feedbackReducer } from './pages/feedback';
import migrations, { createMigrate } from './migrations';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';

const DATE_FORMAT = /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))/;

const replacer = (key: string, value: any) => (value instanceof Date ? value.toISOString() : value);
const dateReviver = (name: any, value: any) => {
  if (typeof value === 'string' && DATE_FORMAT.test(value)) {
    return new Date(value);
  }
  return value;
};

const dateTransform = createTransform(
  toDeshydrate => JSON.stringify(toDeshydrate, replacer),
  toRehydrate => JSON.parse(toRehydrate, dateReviver),
);

export function createRootReducer(storage: Storage) {
  const commonConfig = {
    key: 'common',
    whitelist: commonWhiteList,
    transforms: [dateTransform],
    storage,
    migrate: createMigrate(storage, { 1: (state: any) => ({ ...state, vendors: {} }) }),
  };

  const settingsConfig = {
    key: 'settings',
    whitelist: settingsWhitelist,
    transforms: [dateTransform],
    storage,
  };

  const partitionConfig = {
    key: 'partition',
    whitelist: partitionWhitelist,
    transforms: [dateTransform],
    storage,
  };

  const combinedReducer = combineReducers({
    common: persistReducer(commonConfig, commonReducer),
    settings: persistReducer(settingsConfig, settingsReducer),
    search: searchReducer,
    partition: persistReducer(partitionConfig, partitionReducer),
    feedback: feedbackReducer,
  });

  return combinedReducer;
}

export function createPersistedReducer(storage: Storage) {
  const persistConfig = {
    key: 'root',
    storage,
    whitelist: [],
    version: -1,
    debug: true,
    migrate: createMigrate(storage, migrations),
    stateReconciler: autoMergeLevel2,
  };

  const rootReducer: any = createRootReducer(storage);
  return persistReducer(persistConfig, rootReducer);
}
