import { CardContent, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import * as React from 'react';
import { BaseCard } from './BaseCard';
import InfoIcon from '@material-ui/icons/InfoOutlined';

interface Props {
  errorMessage: string;
}

const ErrorCard = (props: Props) => {
  const classes = useStyles();

  return (
    <BaseCard>
      <CardContent className={classes.row}>
        <InfoIcon color={'secondary'} />
        <Typography className={classes.text}>{props.errorMessage}</Typography>
      </CardContent>
    </BaseCard>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      display: 'flex',
      alignItems: 'center',
      '&:last-child': {
        paddingBottom: theme.spacing(2),
      },
    },
    text: {
      paddingRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
      flexWrap: 'wrap',
    },
  })
);

export default ErrorCard;
