import { createReducer } from 'typesafe-actions';
import produce from 'immer';
import actions from './actions';
import { RootAction } from '../../createStore';
import { IVendorProduct, ProductCondition } from '../../common/models';
import { ApiError } from '../../services/api/apiError';

export interface SearchState {
  isLoading: boolean;
  condition: ProductCondition;
  errors: Record<string, ApiError>;
  products: IVendorProduct[];
  showPriceInfoBanner: boolean;
}

const defaultState: SearchState = {
  isLoading: false,
  products: [],
  condition: ProductCondition.VeryGood,
  errors: {},
  showPriceInfoBanner: true,
};

const reducer = createReducer<SearchState, RootAction>(defaultState)
  .handleAction(actions.fetchProducts.request, (state, action) =>
    produce(state, draft => {
      draft.isLoading = true;
      draft.errors = {};
      draft.products = [];
    })
  )
  .handleAction(actions.fetchProducts.productReceived, (state, action) =>
    produce(state, draft => {
      const { vendor, result } = action.payload;
      if (result instanceof Error) {
        //ToDo: Safer to guard check product instead of error
        draft.errors[vendor] = result;
      } else {
        draft.products.push(result);
      }
    })
  )
  .handleAction(actions.fetchProducts.completed, (state, action) =>
    produce(state, draft => {
      draft.isLoading = false;
    })
  )
  .handleAction(actions.confirmPriceInfoBanner, (state, action) =>
    produce(state, draft => {
      draft.showPriceInfoBanner = false;
    })
  )
  .handleAction(actions.selectProductCondition, (state, action) =>
    produce(state, draft => {
      draft.condition = action.payload;
    })
  );

export default reducer;
