import { Api } from '../api/api';

export class ErrorLogger {

  constructor(private readonly api: Api, private readonly platform: string) {
  }

  public async logError(name: string, error?: unknown | string) {
    try {
      // @ts-ignore
      const message = typeof error === 'string' ? error : (error && typeof error === 'object') ? error.message : undefined;
      await this.api.logError(this.platform, name, message);
    } catch (e) {
      console.error('Failed to log error', e);
    }
  }

  public logFetchLocalMomoxError(error?: unknown) {
    this.logError('fetchLocalMomoxError', error);
  }
}
