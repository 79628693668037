const EAN_PLACEHOLDER = '{ean}';
const EANS_PLACEHOLDER = '{eans}';
const CONDITIONS_PLACEHOLDER = '{conditions}';

export function createAutoTransferUrl(templateUrl: string, eans: string[], conditions: number[], separator: string) {
  const url = templateUrl
    .replace(EANS_PLACEHOLDER, eans.join(separator))
    .replace(CONDITIONS_PLACEHOLDER, conditions.join(separator));
  return encodeUrl(url);
}

export function createSingleTransferUrl(templateUrl: string, ean: string) {
  const url = templateUrl.replace(EAN_PLACEHOLDER, ean);
  return encodeURI(url);
}

export function encodeUrl(url: string): string {
  const searchString = 'param0=';
  const paramStart = url.indexOf(searchString) + searchString.length;
  if (paramStart < searchString.length) {
    return encodeURI(url);
  }

  const firstPart = url.slice(0, paramStart);
  const secondPart = url.slice(paramStart);

  return encodeURI(firstPart) + encodeURIComponent(secondPart);
}
