declare global {
  interface Array<T> {
    firstOrNull(): T | null;
    firstOrDefault(defaultValue: T): T;
    sortedBy(key: keyof T, order: 'ASC' | 'DESC'): T[];
    sum(): number;
    sumBy(key: keyof T): number;
    without(elements: T[], comparator: (e1: T, e2: T) => boolean): T[];
  }
}

Array.prototype.firstOrDefault = function <T>(defaultValue: T): T {
  if (this && this.length >= 1) {
    return this[0];
  } else {
    return defaultValue;
  }
};

Array.prototype.firstOrNull = function <T>(): T | null {
  if (this && this.length >= 1) {
    return this[0];
  } else {
    return null;
  }
};

Array.prototype.sortedBy = function <T>(key: keyof T, order: 'ASC' | 'DESC'): T[] {
  const orderFactor = order === 'ASC' ? 1 : -1;
  return this.slice().sort((a, b) => (a[key] - b[key]) * orderFactor);
};

Array.prototype.sumBy = function <T>(key: keyof T): number {
  return this.reduce((acc, curr) => acc + curr[key], 0);
};

Array.prototype.sum = function (): number {
  return this.reduce((acc, curr) => acc + curr, 0);
};

Array.prototype.without = function <T>(elements: T[], comparator: (e1: T, e2: T) => boolean): T[] {
  const newArray: T[] = [];
  let elementsToRemove = [...elements];
  this.forEach(e1 => {
    const index = elementsToRemove.findIndex(e2 => comparator(e1, e2));
    if (index >= 0) {
      elementsToRemove.splice(index, 1);
    } else {
      newArray.push(e1);
    }
  });
  return newArray;
};

export {};
