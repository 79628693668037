import { Box, Button, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import * as React from 'react';
import { CSSProperties } from 'react';
import { Link } from 'react-router-dom';
import { ISellableProductsHeader } from '@luso/common/src/pages/partition/models';

const SellableProductsHeader = (props: ISellableProductsHeader & { style: CSSProperties }) => {
  const classes = useStyles();

  return (
    <Box boxShadow={2} style={props.style} className={classes.box}>
      <div className={classes.container}>
        <div>
          <Typography variant={'caption'}>{props.vendorDisplay}</Typography>
          <Typography variant={'h6'} color={'primary'} className={classes.price}>
            {props.price}
          </Typography>
          <Typography variant={'caption'}>{props.shippingCost}</Typography>
        </div>
        {props.autoTransferUrl && (
          <Button
            href={props.autoTransferUrl}
            target={'_blank'}
            rel={'noopener'}
            size={'small'}
            variant="contained"
            color={'secondary'}
          >
            Verkaufen
          </Button>
        )}
        {!props.autoTransferUrl && (
          <Button
            component={Link}
            to={`/checkout/${props.vendor}`}
            size={'small'}
            variant="contained"
            color={'secondary'}
          >
            Verkaufen
          </Button>
        )}
      </div>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    box: {
      backgroundColor: theme.palette.common.white,
      zIndex: 1,
    },
    container: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: theme.spacing(2),
    },
    price: {
      lineHeight: 0.9,
    },
  })
);

export default SellableProductsHeader;
